.contact-card {
  border: 1px solid var(--light-gray);
  border-radius: 10px;
  box-shadow: var(--box-shadow-card);
  margin-top: 1rem;
  padding: 0.5rem 0;
  position: relative;
  width: 48%;

  > .btn-close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  .contact-card-avatar {
    background-size: 80px;
    border-radius: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2),
      inset 2px 4px 4px -2px RGBa(255, 255, 255, 0.2);
    height: 80px;
    margin: 0 0 0 1rem;
    width: 80px;
  }

  span {
    font: var(--dates-timestamp);
  }

  // .attendee-invite-icon{
  //   border-radius: 100%;
  //   height: 10px;
  //   margin-right: 0.25rem;
  //   margin-top: -2px;
  //   width: 10px;

  //   &.Confirmed{
  //     background-color: var(--accent-green);
  //   }

  //   &.Invited{
  //     background-color: var(--accent-yellow);
  //   }

  //   &.Declined{
  //     background-color: var(--accent-red);
  //   }
  // }
}
