header#global-header {
  align-items: center;
  background-color: transparent;
  color: var(--white);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 72px;
  padding: 0 2rem;

  section {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    // hamburger + nav title
    &:nth-child(1) {
      #nav-menu-icon {
        margin: 0;

        &:hover {
          cursor: pointer;
        }
      }

      .logo {
        background: url('../assets/files/logo-big-DocVisor.png');
        background-color: transparent;
        background-origin: padding-box;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 72px;
        margin: 0 auto;
        width: 120px;

        &:hover {
          cursor: pointer;
        }
      }

      span {
        font: normal 400 26px/72px 'Nunito', sans-serif;
        margin: 0 1rem;
      }

      h2 {
        font: normal 500 20px/72px 'Rubik', sans-serif;
        margin: 0 auto;

        div#docspera-logo {
          background: url('../assets/docspera-logo-0098e2.svg');
          background-color: transparent;
          background-origin: padding-box;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          height: 60px;
          margin: 0 0 0.2rem 0.6rem;
          width: 120px;
        }
      }
    }

    // user element
    &:nth-child(2) {
      font-weight: 500;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      #nav-user-caret {
        margin-left: 0.5rem;
      }
    }
  }

  #user-menu {
    background-color: var(--white);
    box-shadow: var(--box-shadow-card);
    color: var(--black);
    position: absolute;
    right: 0;
    top: 2rem;
    min-width: 175px;
    z-index: 6;

    > div {
      font-weight: 400;
      padding: 0.5rem 1rem;
      transition: var(--hover-transition);

      &:not(:last-child) {
        border-bottom: 1px solid var(--light-gray);
      }

      &:hover {
        background-color: var(--light-blue);
        cursor: pointer;
      }
    }

    #user-id {
      background: var(--light-gray);
      border-radius: 10px;
      font: var(--dates-timestamp);
      font-weight: 500;
      padding: 0 0.25rem;
    }
  }
}
