#pending-cases {
  overflow: scroll;

  ol.docspera-table li.table-row {
    grid-template-columns: 12% 12% 29% 8% 29% 8%;
  }

  .header {
    display: flex;
    margin-top: 70px;
    position: fixed;
    left: 0;
    right: 0;
    background: rgba(220, 222, 224, 0.4);
    z-index: 9;

    form {
      background: Linear-Gradient(
        to bottom,
        #ffffff 30%,
        rgba(220, 222, 224, 0.3) 120%
      );
      width: 100%;
      padding: 10px 0px 0 20px;

      &:hover {
        background: Linear-Gradient(
          to bottom,
          #ffffff 30%,
          rgba(179, 224, 246, 0.8) 120%
        );
      }

      .labelAndDropdown {

        label {
          color: white;
          position: absolute;
          top: 17px;
          font-weight: 800;
          font-size: 16px;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
          left: 35px;
          z-index: 100;
          }

        .select-icon {
          position: absolute;
          top: 16px;
          left: 12px;
          font-size: 15px;
          color: white;
          opacity: 0.6;
          margin-top: 2px;
          z-index: 100;
        }

        select {
          padding-right: 30px;
          padding-left: 130px;
          width: 300px;
          position: relative;
        }

        .select-arrow {
          position: absolute;
          right: 30px;
        }
      }
    }

    form {
      display: flex;
    }
  }

  h1.pending-cases-header {
    font-size: 16px;
  }

  .readiness-td {
    padding: 10px;
  }
}
