.dropdown-menu {
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  z-index: 1000;
}

.dropdown-item {
  display: block;
  // width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--black);
  // text-align: inherit;
  white-space: nowrap;
  // background-color: transparent;
  border: 0;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: var(--hover-transition);

  &:hover {
    text-decoration: none;
    background-color: var(--light-gray-bg);
  }

  img {
    vertical-align: middle;
    border-style: none;
  }
}
