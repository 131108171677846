#schedule {
  .event {
    background-color: white;
    border: 1px solid white;
    border-radius: 0 !important;
    filter: drop-shadow(0px 0px 5px RGBa(0, 0, 0, 0.17));
    text-align: left;

    .fc-event-main {
      flex-wrap: nowrap;
    }

    .case-icon-container {
      padding-right: 4px;

      > .case-icon {
        border-radius: 100%;
        height: 20px;
        // left: 1rem;
        width: 20px;

        &.surgery {
          border-color: RGBa(255, 255, 255, 1);
        }

        &.clinical {
          //pre invite to telehealth
          border-color: RGBa(255, 255, 255, 1);
        }

        &.telehealth {
          //invited to telehealth & ready to join
          background: var(--dark-blue)
            url('../../assets/img/event-icons/telehealth-icon.svg') 50% 50%
            no-repeat padding-box;
          background-size: 62%;
          border-color: RGBa(255, 255, 255, 1);
        }

        &.conference {
          border-color: RGBa(255, 255, 255, 0.3);
        }

        &.vacation {
          border-color: RGBa(255, 255, 255, 0.3);
        }
      }
    }

    &.surgery {
      border-left: 0.35rem solid var(--calendar-surgery);
    }

    &.ready {
      border-left: 0.35rem solid var(--velysReadinessGreen);
    }

    &.pending {
      border-left: 0.35rem solid var(--velysReadinessYellow);
    }

    &.clinical {
      border-left: 0.35rem solid var(--calendar-clinical);
    }

    &.telehealth {
      border-left: 0.35rem solid var(--dark-blue);
    }

    &.vacation {
      border-left: 0.35rem solid var(--calendar-vacation);
    }

    &.conference {
      border-left: 0.35rem solid var(--calendar-conference);
    }

    &:hover {
      cursor: pointer;
    }
  }

  Table th {
    box-shadow: 0 2px 0px RGBa(0, 0, 0, 0.06);
  }

  // tbody tr:nth-child(1), Table tr:nth-child(5), Table tr:nth-child(9) {
  //   border-bottom: 2px solid blue;
  // }

  .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    height: calc(90vh);
  }

  .fc-timegrid-event-harness {
    &.on-hover {
      z-index: 100 !important;
      // width: 100%;
      // position: absolute;
      left: 0px !important;

      > .fc-timegrid-event {
        &.on-hover {
          overflow-wrap: break-word;
          filter: drop-shadow(0 1px 10px RGBa(0, 0, 0, 0.27));
          z-index: 100;
          overflow: visible;

          //used instead of height: max-content (does not work in Safari)
          display: table;
          width: 102%;
        }
      }
    }
  }

  th.fc-timegrid-axis {
    background: #b3e0f6;
  }

  .fc-col-header-cell {
    font: Normal 600 0.9em 'Open Sans', Arial, Sans-Serif;
    text-transform: UpperCase;
    background: #b3e0f6;
    vertical-align: Middle;
    // border-bottom: 2px Solid #0098e2;
    padding: 10px 0;
    border-right: 1px Solid White;
  }

  a.fc-col-header-cell-cushion {
    color: var(--black);
  }

  .fc-view-harness {
    z-index: 0;
  }

  .fc .fc-view-harness-active > .fc-view {
    bottom: 2rem;
    left: -2rem;
    right: 2rem;
    top: -1rem;
  }

  .fc-header-toolbar {
    display: none !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    border-radius: 10px 0 0 0;
    margin-left: 30px;
    margin-top: 30px;
    box-shadow: var(--box-shadow-card);
    z-index: -10;
    height: 100%;
  }

  .fc-col-header-cell-cushion {
    font-family: 'Open Sans', Arial, Sans-Serif !important;
    font-size: 16px;
    font-weight: 500;
  }

  .fc-timegrid-now-indicator-line {
    border-color: var(--velys-blue-og) !important;
    border-width: 1px !important;
    box-shadow: var(--box-shadow-card);
  }

  .fc-timegrid-now-indicator-arrow {
    border-color: var(--velys-blue-og) !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  .fc-timegrid-event-harness > .fc-timegrid-event {
    left: -1px;
    right: -3px;
    padding: 0px 3px;
    overflow: hidden;
  }

  .fc-event-main {
    color: var(--black) !important;
    font-size: 0.7rem;
    width: 100%;

    .event-description {
      padding-bottom: 10px;
      position: relative;
      width: 100%;

      .telehealth-join-button {
        position: absolute;
        top: 2px;
        right: -2px;
        padding: 0;
        font-size: 12px;
        color: var(--dark-blue);
        border-radius: 0;
        border-left: 1px solid var(--light-gray);
        padding-left: 4px;
        height: 15px;

        &:hover {
          color: black;
          cursor: pointer;
        }
      }

      div {
        &:not(:first-child) {
          margin-top: 5px;
        }

        label {
          color: var(--white);
          font-size: 0.6rem;
          font-weight: 600;
          text-transform: uppercase;
        }

        span {
          display: block;
          line-height: 1;
          // margin-top: 2px;
          // margin-bottom: 1px;
        }

        .title,
        .time,
        .location,
        .patient,
        .risk-score {
          color: var(--black) !important;
          font-weight: 500;
          margin-top: 5px;
          font-size: 0.7rem;
        }
      }
    }
  }

  .absolute {
    position: absolute;
    // bottom: calc(0.5rem + 100%);
    right: 0;
    // margin-right: -2px;
    // margin-left: -2px;
    // display: none;
    padding: 5px;
    border-radius: 5px;
    // height: 100px;
    height: fit-content;
    width: 100px;
    box-shadow: var(--box-shadow-card);

    // TODO: get triangle to show up successfully

    // &:before {
    //   content: '';
    //   bottom: calc(0.5rem + 100%);
    //   right: 50%;
    //   transform: translateX(50%);
    // }

    // &:after {
    //   content: '';
    //   border-top-width: 0.5rem;
    //   border-right-width: 0.5rem;
    //   border-right-color: #0000;
    //   border-left-width: 0.5rem;
    //   border-left-color: #0000;
    //   bottom: 100%;
    //   right: 50%;
    //   transform: translateX(50%);
    // }
  }

  .fc-event-main:hover .absolute {
    display: block;
  }

  .fc-non-business {
    background: url('../../assets/Throbber_Lines_Red_50pc.png') !important;
    background-size: 20% !important;
    opacity: 0.7;
  }

  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    font-size: 0.8rem;
  }

  // .fc-timegrid-slot-label-cushion:nth-child(n+2) {
  //   background-color: blue;
  // }

  .no-cases {
    background-color: rgb(255, 255, 255);
    height: fit-content;
    width: fit-content;
    border-radius: none;
    position: absolute;
    left: 32%;
    top: 20%;
    z-index: 1000;
    text-align: center;
    padding: 25px;
    border: 1px;
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.2);
    font-family: 'Open Sans', Arial, Sans-Serif;

    p {
      margin-top: 0px;
      margin-bottom: 15px;
      font-size: 23px;
      font-weight: 600;
    }

    div {
      font-size: 14px;
      font-weight: 600;
    }

    p,
    > div {
      color: var(--light-gray) !important;
    }

    button.create-event {
      background-color: #4d90fe;
      border: medium none !important;
      border-radius: 37px !important;
      color: white;
      font-size: 16px;
      height: 30px;
      padding: 0px 25px;
      margin-top: 20px;

      &:hover {
        background-color: #0362fd !important;
      }
    }
  }

  .loading-bg {
    background-color: rgb(255, 255, 255, 0.4);
    width: 97%;
    height: calc(84vh);
    border-radius: none;
    position: absolute;
    top: 140px;
    z-index: 1000;

    .weekly-loading {
      background: #fcfcfc url('../../assets/files/loading.gif') No-Repeat;
      width: 128px;
      height: 128px;
      text-align: Center;
      font: Normal Normal 18px/128px 'Open Sans', Helvetica, Arial !important;
      color: #50b6f5;
      margin: auto;
      border: 1px Solid #fcfcfc;
      border-radius: 100% !important;
      -moz-border-radius: 100% !important;
      -webkit-border-radius: 100% !important;
      -moz-box-shadow: Inset 0 0 10px #fff;
      -webkit-box-shadow: Inset 0 0 10px #fff;
      box-shadow: Inset 0 0 10px #fff;
      background-color: white;
      margin-top: 18%;
      z-index: 1001;

      &:after {
        content: 'Loading';
      }
    }
  }
}

@media (max-width: 1400px) {
  #schedule .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
    height: calc(87vh);
  }
}

@media (max-width: 1250px) {
  #schedule .loading-bg {
    top: 192px;
  }
}
