.billing-code-badge {
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  box-shadow: var(--box-shadow-card);
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem;
  font-weight: 700;

  > span {
    margin-right: 0.5rem;
  }
}
