#pd-settings {
  #calendar {
    display: none;
  }

  #times {
    display: none;
  }

  ol.docspera-table li {
    grid-template-columns: 3fr 4fr 2fr;
  }

  .table-cell {
    align-items: center;
  }

  #velys-admin {
    span {
      font-style: italic;
      color: red;
    }

    small {
      color: grey;
      font-size: 12px;
    }
  }

  .multi-admin {
    display: flex;
  }

  #admin-selected {
    background-color: black;
    border-radius: 20px;
    font-weight: 500;
    color: white;
    border: 1px solid var(--light-gray);
    display: flex;
    height: 25px;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-left: 15px;
    min-width: 110px;
    max-width: 150px;
  }
}

.practice-members-col {
  display: flex;

  .settings-avatar {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    background-color: var(--light-blue);
    border-radius: 100px;
    margin: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    margin-right: 50px;

    p {
      margin: 0;
      color: var(--medium-gray);
      font-weight: 600;
    }

    span {
      color: var(--medium-gray);
      font-size: 12px;
    }
  }
}
