#all-cases {
  width: 100%;
  background: var(--white);
  min-height: 100%;
  height: 100%;
  overflow: scroll;
  font-family: 'Open Sans', Sans-Serif !important;

  ol.docspera-table li.table-row {
    min-width: 1000px;
    grid-template-columns: 10% 10% 22% 10% 6.5% 10% 31.5%;

    .date-td {
      // min-width: 150px;

      span {
        font-weight: 600;
      }

      p {
        margin-top: 5px;
        font-size: 0.9em;
        font-weight: 400;
        color: var(--pd-light-gray);
        letter-spacing: -0.2px;
      }
    }

    .physician-td {
      // min-width: 130px;
      span {
        font-weight: 600;
      }
    }

    .case-td {
      font-weight: 400;

      > span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100vw / 4.3);
      }

      .patient-data {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        .patient-name {
          margin-left: 5px;
          color: var(--black);
          font-size: 0.9em;
        }

        div:nth-child(2) {
          text-align: right;
          font-weight: 400;

          span {
            font-size: 0.9em;
            color: var(--pd-light-gray);
          }
        }
      }
    }

    .location-td {
      font-weight: 500;
    }

    .readiness-td {
      padding: 10px;
    }

    .participants-td {
      font-size: 12px;
      text-overflow: ellipsis;
      color: var(--black);
      font-weight: 400;

      .care-team {
        display: block;
        height: 31px;
        line-height: 1.2;
        overflow: hidden;
        white-space: normal;
        word-break: break-word;
      }

      .rep {
        height: 15px;
        margin-top: 10px;
        overflow: hidden;
        white-space: normal;
        word-break: break-word;
      }
    }

    .notes-td {
      font-size: 12px;
      font-weight: 400;

      .notes-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        > span {
          width: 98%;
          overflow: hidden;
          white-space: normal;
          word-break: break-word;
          height: 31px;
          line-height: 1.2;
          text-overflow: ellipsis;
          max-width: calc(100vw / 4.3);
        }
      }

      .devices {
        height: 15px;
        margin-top: 10px;
        overflow: hidden;
        white-space: normal;
        word-break: break-word;
      }
    }
  }

  .header {
    display: flex;
    margin-top: 70px;
    position: fixed;
    left: 0;
    right: 0;
    background: rgba(220, 222, 224, 0.4);
    z-index: 5;
    white-space: pre-wrap;

    form {
      background: Linear-Gradient(
        to bottom,
        #ffffff 30%,
        rgba(220, 222, 224, 0.3) 120%
      );
      width: 100%;
      padding: 10px 0px 0 0px;

      &:hover {
        background: Linear-Gradient(
          to bottom,
          #ffffff 30%,
          rgba(179, 224, 246, 0.8) 120%
        );
      }
    }
  }
}

.no-cases {
  margin-top: 200px;
  margin-left: 50px;

  @media (max-width: 928px) {
    margin-top: 270px;
  }
  @media (max-width: 466px) {
    margin-top: 430px;
  }
  // @media (max-width: 375px)  {
  //   margin-top: 270px;
  // }
}

.labelAndDropdown {
  flex-direction: column;
  padding-right: 20px;
  padding-bottom: 10px;
  position: relative;
  display: flex;
  letter-spacing: -0.3px;

  &:first-child {
    padding-left: 20px;
  }

  label {
    color: white;
    position: absolute;
    top: 17px;
    font-weight: 800;
    font-size: 16px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
  }

  label {
    left: 35px;
  }

  .select-icon {
    position: absolute;
    top: 16px;
    left: 12px;
    font-size: 15px;
    color: white;
    opacity: 0.6;
    margin-top: 2px;
  }

  .select-arrow {
    position: absolute;
    top: 16px;
    right: 30px;
    color: white;
    filter: drop-shadow(0 1px 2px RGBa(0, 0, 0, 0.37));
    z-index: 10;
  }
}

select {
  cursor: pointer !important;
  border: none !important;
  color: white;
  border-radius: 4px !important;
  box-shadow: Inset 0 0 3px 0 rgba(0, 0, 0, 0.47),
    2px 1px 1px 0 rgba(255, 255, 255, 1), -2px 1px 1px 0 rgba(255, 255, 255, 1),
    0 2px 1px 0 rgba(255, 255, 255, 1);
  padding: 2px 5px;
  font-family: 'Open Sans', sans-serif;
  outline: none;
  background: linear-gradient(to bottom, #05a4f1 20%, #006ac9 80%) !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
  appearance: none;

  &#type {
    padding-left: 86px;
    width: 175px;

    &.changed-type {
      width: 200px;
    }
  }

  &#location {
    padding-right: 30px;
    padding-left: 126px;
    width: 215px;

    &.changed-loc {
      width: min-content;
    }
  }

  &#physician {
    padding-right: 30px;
    padding-left: 130px;
    width: 220px;

    &.changed-phys {
      width: 300px;
    }
  }

  option {
    color: black;
    background-color: #f7f8fb;
  }
}

.input-container {
  position: relative;
  float: right;

  .search-input {
    margin-top: 24px;
  }
}

.search-icon {
  width: 30px;
  position: absolute;
  left: 2px;
  top: 27px;
}

.search-input {
  max-width: 175px;
  min-width: 50px;
  margin-top: 25px;
  padding-left: 30px;
}

.table {
  padding-left: 32px;
}

.table-header-span {
  font-family: var(--table-header);
  text-transform: uppercase;
}

.table-body {
  background-color: white;
  padding: 0px 10px 10px 10px;
}

.small-button {
  background-color: var(--blueDocspera);
}

button.btn-warning {
  background: #fe4d4d;
}

.table-container {
  margin-top: 143px;

  .pd-loading {
    background-size: 12em 12em;
    width: 100%;
    height: 12em !important;
    text-align: Center;
    letter-spacing: -0.5px;
    cursor: Wait !important;
    font: Normal 700 0.98em/12.4em 'Open Sans', Arial, Sans-Serif !important;
    color: #2c94e0;
    margin-top: 100px;
    background: url('../../../assets/files/Throbber-2C94E0.svg') no-repeat
      center center;
  }

  @media (max-width: 928px) {
    margin-top: 250px;
  }

  @media (max-width: 481px) {
    margin-top: 330px;
  }
  @media (max-width: 466px) {
    margin-top: 410px;
  }
}
