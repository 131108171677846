.button-container {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px 0px rgba(0, 0, 0, 0.12);
  padding: 7px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  img {
    height: 18px;
    margin-right: 10px;
  }

  .upload-spinner {
    h1 {
      height: 18px;
      line-height: 0px;

      svg {
        margin-top: 0px !important;
        height: 18px;
      }
    }
  }

  .btn-upload {
    background: none;
    font-family: Rubik;
    color: #438fe0;
    height: fit-content;
    padding: 0;
  }
}
