.fc-view-harness {
  z-index: 0;
}

.fc-header-toolbar {
  display: none !important;
}

.fc-theme-standard .fc-scrollgrid {
  height: calc(100vh - 160px);
}

.fc-col-header-cell-cushion {
  font-family: 'Rubik' !important;
  font-size: 14px;
  font-weight: 500;
}

.fc-timegrid-now-indicator-line {
  border-color: var(--blueDocspera) !important;
  border-width: 1px !important;
  box-shadow: var(--box-shadow-card);
}

.fc-timegrid-now-indicator-arrow {
  border-color: var(--blueDocspera) !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

.fc-timegrid-event-harness > .fc-timegrid-event {
  left: -3px;
  right: -3px;
  padding: 2px 3px;
  // position: relative;
  overflow: hidden;
}

.fc-event-main {
  color: black !important;

  span {
    display: block;
    line-height: 1;
    margin-top: 2px;
    margin-bottom: 1px;
  }

  .title {
    font-weight: 500;
  }

  .time {
    color: var(--medium-gray);
    font-size: 10px;
    font-weight: 500;
  }

  .location {
    color: var(--medium-gray);
    font-size: 10px;
  }

  .patient {
    font-size: 10px;
    font-weight: 500;
    margin-top: 5px;
  }
}

.absolute {
  position: absolute;
  bottom: calc(0.5rem + 70%);
  margin-right: -2px;
  margin-left: -2px;
  display: none;
  padding: 5px;
  border-radius: 5px;
  box-shadow: var(--box-shadow-card);

  // TODO: get triangle to show up successfully

  // &:before {
  //   content: '';
  //   bottom: calc(0.5rem + 100%);
  //   right: 50%;
  //   transform: translateX(50%);
  // }

  // &:after {
  //   content: '';
  //   border-top-width: 0.5rem;
  //   border-right-width: 0.5rem;
  //   border-right-color: #0000;
  //   border-left-width: 0.5rem;
  //   border-left-color: #0000;
  //   bottom: 100%;
  //   right: 50%;
  //   transform: translateX(50%);
  // }
}

.fc-event-main:hover .absolute {
  display: block;
}

.fc-non-business {
  background: url('../../../assets/Unavailable_lines.png') !important;
  background-size: 200% !important;
  opacity: 0.7;
}

.surgery {
  background-color: white;
  border: 2px solid var(--calendar-surgery);
}

.clinical {
  background-color: white;
  border: 2px solid var(--calendar-clinical);
}

.vacation {
  background-color: white;
  border: 2px solid var(--calendar-vacation);
}

.conference {
  background-color: white;
  border: 2px solid var(--calendar-conference);
}
