.calendar-picker-container {
  // position: relative;

  .fa-calendar-alt {
    color: white;
    margin-right: 12px;
    font-size: 10px;
    margin-bottom: 2px;
    opacity: 0.6;
  }

  .calendar-picker-input {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Open Sans', Sans-Serif !important;
    font-weight: 500;

    &#pd-week,
    &#pd-day {
      padding: 0.55rem 0;
      margin: 0.5rem 0;
      width: 228px !important;
      font-size: 14px;
      padding-left: 40px;

      .fa-calendar-alt {
        font-size: 15px;
        position: absolute;
        left: 35px;
      }
    }

    &#pd-day {
      width: 315px !important;
      padding-left: 35px;
      font-size: var(--body-text-small);
      height: 18px;
    }

    > span {
      margin-right: 10px;
      font-weight: 800;
      font-size: 16px;
    }
  }

  .calender-input input {
    border: none;
    padding: 0px 10px;
    height: 27px;
  }

  .calender-input > div > div {
    padding: 10px;
  }

  .calender-input input:focus {
    border: none;
    box-shadow: none;
    animation: none;
  }

  .calendar-picker-popover {
    z-index: 9999;
    background-color: var(--white);
    box-shadow: var(--box-shadow-card);
    border: 1px solid var(--light-gray);
    border-radius: 10px;
    min-height: 44px;
    position: absolute;
    width: 350px;

    > header {
      position: relative;
      padding-top: 10px;
      background-color: #0096fa;
      border-top: 1px solid var(--light-gray);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      svg {
        filter: invert(99%) sepia(2%) saturate(147%) hue-rotate(296deg)
          brightness(120%) contrast(100%) !important;
        transform: scale(1.5);
      }

      > div:first-child,
      > div:last-child {
        border-radius: 6px;
        height: 30px;
        transition: var(--hover-transition);
        width: 30px;

        &:hover {
          background-color: var(--light-gray);
          cursor: pointer;
        }
      }

      > div:nth-child(2) {
        margin: 0 0.5rem;
        padding: 0.5rem;
        text-align: center;
        width: 225px;

        h4 {
          color: var(--white) !important;
          font-weight: 700;
          font-size: 20px;
          margin: 0 auto;
          > span {
            font-weight: 500;
          }
        }
      }
    }

    #weekdays {
      background-color: #0096fa;
      color: var(--white) !important;
      border-bottom: 1px solid var(--light-gray);
      font-weight: 500;
      padding: 10px 0;
      text-transform: uppercase;

      > div {
        width: 48px;
        text-align: center;
      }
    }

    #month {
      > .week {
        > .day {
          height: 48px;
          font-weight: 500;
          transition: background-color 150ms ease-in-out,
            color 150ms ease-in-out;
          width: 48px;
          position: relative;

          &.today {
            position: relative;
            border-bottom: 4px solid var(--dark-blue);
          }

          .today-circled {
            position: absolute;
            width: 85%;
            height: 85%;
            border-radius: 50%;
            border: 2px Solid #d35037;

            &:hover {
              cursor: pointer;
              color: var(--black) !important;
            }
          }

          // .today-squared {
          //   position: absolute;
          //   width: 85%;
          //   height: 85%;
          //   border-radius: 25%;
          //   border: 2px Solid #D35037;
          //   background-color: transparent;
          //   z-index: -1;

          //   &:hover {
          //     cursor: pointer;
          //     color: var(--black) !important;
          //   }
          // }

          // &.today{
          //   position: relative;
          //   &:after {
          //     content:'';
          //     position:absolute;
          //     bottom:-4px;
          //     left:0;
          //     right:0;
          //     background:var(--dark-blue);
          //     height:4px;
          //     border-radius: 10px;
          //   }
          // }

          > div {
            border-radius: 100%;
            height: 48px;
            transition: background-color 150ms ease-in-out,
              color 150ms ease-in-out;
            width: 48px;

            &.today-circled {
              height: 40px;
              width: 40px;
              color: var(--black);
              // border-radius: 25%;
              border: 2px Solid #d35037;
              z-index: 1;

              &:hover {
                color: var(--black);
                background-color: var(--light-gray) !important;
              }
            }

            &.selected {
              // border: 2px solid var(--black);
              height: 40px;
              width: 40px;
              background-color: #0096fa;
              color: var(--white);
              border-radius: 100%;
            }

            &.today-selected {
              height: 40px;
              width: 40px;
              background-color: #002855;
              color: var(--white);
              // border-radius: 25%;
              border: 2px Solid #d35037;
              z-index: 1;

              &:hover {
                color: var(--black);
                background-color: var(--light-gray) !important;
              }
            }

            &.week-selected {
              background-color: #0096fa;
              color: var(--white);
              border-radius: 0;
            }

            &.left-bookend {
              border-radius: 10% 0 0 10%;
            }

            &.right-bookend {
              border-radius: 0 10% 10% 0;
            }

            &.not-month:not(.week-selected) {
              opacity: 0.5;
            }

            &:hover {
              background-color: var(--light-gray) !important;
              cursor: pointer;
              color: var(--black) !important;
            }
          }
        }
      }
    }
  }
}

.calendar-picker-input {
  cursor: pointer !important;
  border: none;
  color: white;
  border-radius: 4px !important;
  text-align: center;
  box-shadow: Inset 0 0 3px 0 rgba(0, 0, 0, 0.47),
    2px 1px 1px 0 rgba(255, 255, 255, 1), -2px 1px 1px 0 rgba(255, 255, 255, 1),
    0 2px 1px 0 rgba(255, 255, 255, 1);
  padding: 2px 5px;
  font-family: 'Open Sans', sans-serif;
  outline: none;
  background: linear-gradient(to bottom, #05a4f1 20%, #006ac9 80%) !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
}

#menu-week {
  .MuiPopover-paper {
    display: none;
  }
}
