#access-rights-toggle {
  background-color: white;
  border: 1px solid var(--light-gray);
  border-radius: 100px;
  display: flex;
  height: 25px;
  justify-content: flex-start;
  padding: 5px;
  transition: all 150ms ease-in;
  padding: 2px;
  min-width: 300px;
  max-width: 400px;

  &:hover {
    cursor: pointer;
  }

  div {
    color: var(--light-gray);
    padding: 2px 8px;
    user-select: none;
    text-align: center;

    &:hover {
      color: black;
    }
  }

  .none-selected {
    color: white;
    background-color: var(--velysReadinessRed);
    border-radius: 20px;
    font-weight: 500;
  }

  .read-selected {
    color: black;
    background-color: var(--accent-yellow);
    border-radius: 20px;
    font-weight: 500;
  }

  .full-selected {
    color: white;
    background-color: var(--risk-low-green);
    border-radius: 20px;
    font-weight: 500;
  }
}
