.attachment-card {
  border: 1px solid var(--light-gray);
  border-radius: 10px;
  margin-top: 1rem;
  padding: 0.5rem 0;
  position: relative;

  > .btn-close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  .attachment-card-icon {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2),
      inset 2px 4px 4px -2px rgba(255, 255, 255, 0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    width: 80px;
    height: 80px;
    margin: 0 0 0 1rem;
  }

  input[type='checkbox'] {
    margin: 0 0.5rem 0 0;
  }

  p {
    font: var(--dates-timestamp);
    line-height: 20px !important;
    margin: 0 !important;

    b {
      font-weight: 500 !important;
    }
  }

  a {
    color: var(--dark-blue);
    transition: var(--hover-transition);

    &:hover {
      color: var(--icon-active-gray);
    }

    > span {
      font-weight: 400;
    }
  }

  span {
    font: var(--dates-timestamp);
  }
}
