#alert {
  background: var(--white);
  border-radius: 10px;
  box-shadow: 3px 3px 20px 5px #444444;
  left: 50%;
  max-height: 700px;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  z-index: 999999;

  h1 {
    font: var(--main-h1);
    font-weight: 600;
    margin: 0;
    border-bottom: 2px solid var(--alertDocsperaBlue);
    color: var(--alertDocsperaBlue);
    padding: 1.5rem;
  }

  > div {
    padding: 1rem;

    > div {
      color: #555555;
    }
  }

  #alert-btn-container {
    margin-top: 1rem;
    align-items: baseline;

    button {
      font-weight: 600;
      border-radius: 4px;
      background-color: white;
      color: gray;

      &:hover {
        border: none;
        background: rgba(0, 0, 0, 0.1);
        cursor: Pointer;
      }
    }

    > button:first-child {
      border: none;
    }

    > button:last-child {
      margin: 1rem;
      transition: all 0.3s ease;
      color: var(--alertDocsperaBlue);
    }
  }
}

#alert-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999998;
}

.primary-btn button.close-alert {
  color: var(--white);
}
