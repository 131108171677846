.docspera-tableBG,
.ksrc-tableBG {
  ol.docspera-table,
  ol.ksrc-table {
    font-family: 'Open Sans', sans-serif;
    margin-top: 140px;
    margin-bottom: 0px;
    padding: 0px;

    li {
      list-style: none;

      // TABLE HEADER ROW
      &.table-header {
        background-color: rgba(0, 152, 226, 0.3);
        color: Black;
        border-bottom: 2px Solid rgba(0, 152, 226, 1);
        font: Normal 600 14px 'Open Sans', Arial, Sans-Serif;
        text-align: Left;
        text-transform: UpperCase;
        line-height: 1.3;
        overflow-wrap: break-word;
        word-break: break-word;

        .docspera-th {
          padding: 10px !important;
          border-bottom: none !important;
        }

        .ksrc-th {
          padding: 15px 10px !important;
        }

        // TABLE HEADER CELLS
        .table-cell {
          &.active {
            background-color: rgba(0, 152, 226, 1) !important;
            color: white;
            cursor: default !important;
          }

          &:not(:last-child) {
            border-right: 1px solid var(--white) !important;
          }

          .sort-icon {
            &.up {
              transform: rotate(0deg);
            }
            &.down {
              transform: rotate(180deg);
            }
          }
        }
      }

      &.table-row {
        display: grid;

        // ALL TABLE CELLS
        > div {
          &.table-cell {
            display: flex;
            padding: 8px 10px;
            word-wrap: break-word;

            &.ksrc-td {
              padding: 10px 10px;

              .tiny-button {
                font-size: 1.02em;
                padding: 0px 0px;
                height: 25px;
                letter-spacing: 0px;
                width: 115px;
                -webkit-text-stroke: 0.2px;
                font-stretch: 50% !important;
                font-weight: 600;
                text-transform: initial;
                margin-left: 2px;
              }
            }

            &:not(:last-child) {
              border-right: 1px solid var(--pd-border-right);
            }

            &.container {
              display: grid;
              grid-template-columns: repeat(
                auto-fit,
                minmax(var(--column-width-min), 1fr)
              );
            }

            #all-cases &.docspera-th.col-4,
            #pending-cases &.docspera-th.col-3 {
              padding: 10px !important;
            }

            #all-cases &.docspera-td.col-4,
            #pending-cases &.docspera-td.col-3,
            #all-cases-ksrc &.ksrc-td.col-4 {
              padding: 0px;
            }

            div {
              flex-grow: 1;
            }
          }
        }

        // TABLE BODY ROWS
        &:not(:first-child) {
          background-color: rgba(255, 255, 255, 0.5);
          border-bottom: 1px solid rgba(0, 152, 226, 0.5);

          &:hover {
            cursor: pointer;
            color: blue;
            background: rgba(0, 245, 0, 0.06);
          }

          &:nth-child(odd) {
            background: rgba(0, 0, 150, 0.034);

            &:hover {
              cursor: pointer;
              color: blue;
              background: rgba(0, 245, 0, 0.06);
            }
          }
        }

        &.ksrc-row {
          background-color: rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
  }

  .tiny-button {
    background: linear-gradient(to bottom, #05a4f1 20%, #006ac9 80%);
    font-size: 0.9em;
    padding: 5px 10px;
    height: 25px;
    font-weight: 800;
    border-radius: 4px;
    box-shadow: Inset 0 0 3px 0 RGBa(0, 0, 0, 0.47),
      2px 1px 1px 0 RGBa(255, 255, 255, 1),
      -2px 1px 1px 0 RGBa(255, 255, 255, 1), 0 2px 1px 0 RGBa(255, 255, 255, 1);
    color: white !important;
    transition: all 1s ease;
    text-transform: Uppercase;
    letter-spacing: -0.3px;
    text-shadow: 0 1px 2px RGBa(0, 0, 0, 0.37);
    zoom: 70%;
    width: 110px;
    -webkit-text-stroke: 0.4px;
    font-stretch: 50% !important;

    > svg {
      filter: drop-shadow(0 1px 2px RGBa(0, 0, 0, 0.37));
      margin-right: 5px;
      margin-top: 2px;
    }

    &.add-notes {
      background: linear-gradient(to bottom, #78df46 20%, #1bb51f 80%);

      &:hover {
        background: linear-gradient(to bottom, #78df46 30%, #1bb51f 130%);
      }
    }

    &:hover {
      background: linear-gradient(to bottom, #05a4f1 30%, #0087c9 130%);
    }
  }
}
