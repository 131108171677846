#agenda-view {
  flex-grow: 1;
  padding-left: 1rem;

  header {
    align-items: center;
    display: flex;

    > span {
      margin-left: 1.5rem;
      font: var(--dates-timestamp);
    }
  }

  #agenda-list {
    button.create-event {
      background-color: #4d90fe;
      border: medium none !important;
      border-radius: 37px !important;
      color: white;
      font-size: 15px;
      width: 140px;
      text-align: center;
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      height: 25px;

      &:hover {
        cursor: pointer !important;
        background-color: #0362fd !important;
        text-decoration: none !important;
      }
    }

    .no-events {
      .calendar-img {
        color: white;
        background-image: url(../../assets/img/Calendar-Blank-Black.svg);
        width: 100px;
        height: 100px;
        font: 400 60px/118px 'Open Sans', Sans-Serif;
        opacity: 0.2;
        overflow: Hidden;
        text-align: Center;
        margin-right: 30px;
      }

      div {
        h2 {
          color: Black;
          font: 700 20px/20px 'Open Sans', Sans-Serif !important;
          opacity: 0.4;
          padding: 10px 0 0 0;
          margin: 0;
        }

        h5 {
          color: Black;
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          opacity: 0.4;
          padding: 2px 0 0 0;
        }
      }
    }
  }
}

// agenda view print layout
@media print {
  div#root > header,
  div#root > footer,
  #global-nav-menu,
  .content-container > .tabs,
  #calendar-container {
    display: none;
  }

  #ds-main,
  #schedule,
  #agenda-view {
    padding: 0 !important;
  }

  #ds-main {
    background: var(--white);
  }

  .content-container {
    max-width: none;
  }

  #agenda-view header section:last-child button,
  #agenda-list > div > button {
    display: none;
  }

  .agenda-card {
    margin: 0;
    box-shadow: none;
    border-radius: 0;

    &:first-child {
      margin-top: 2rem;
    }

    &:not(:last-child) {
      border-bottom: 2px solid #212121;
    }
  }
}

@media print {
  @media (max-width: 40em) {
    #agenda-list > .agenda-card {
      &:nth-child(6n) {
        margin-bottom: 72px;
      }
    }
  }
  // @media (orientation: portrait) {
  //   #agenda-list > .agenda-card {
  //   &:nth-child(6n) {
  //     margin-bottom: 70px !important;
  //   }
  // }
  // }
  @page {
    margin-left: 15px;
    margin-right: 15px;
  }
  /** for web_master **/
  HTML,
  BODY,
  .page-content,
  .container-fluid {
    margin: 0 !important;
    padding: 0 !important;
  }

  BODY {
    background: Transparent !important;
    overflow: Visible !important;
  }

  #PrintHeaderContainer {
    display: Block !important;
    margin: 0 10px;
    margin-bottom: 20px;
  }

  #PrintFooter {
    display: Block !important;
  }

  .page-content {
    background: #ffffff !important;
    min-height: initial !important;
    height: Auto;
  }

  .slimScrollDiv,
  .page-sidebar-menu,
  .noprint,
  #agenda-view header,
  .navbar-inner,
  #NoEventList,
  #Docbox_Flag {
    display: None !important;
  }

  #detail {
    width: 100%;
    padding: 0;
  }

  a:link:after,
  a:visited:after {
    content: '';
  }

  a#DocSperaHelp {
    display: None !important;
  }

  #react-schedule {
    height: 100vh !important;
  }
  /** for web_master **/

  div#root > header,
  div#root > footer,
  #global-nav-menu,
  .schedule-container > #calendar-subheader,
  .schedule-container .content-container-2 > .tabs,
  #calendar-container {
    display: none;
  }

  .page-container {
    height: auto !important;
  }

  .schedule-container {
    box-shadow: none !important;
  }

  #ds-main,
  #schedule {
    padding: 0 !important;
    overflow: visible !important;
    width: auto !important;
    background: var(--white);
  }

  #ds-main,
  #agenda-view {
    background: var(--white);
    height: auto !important;
    position: static !important;
  }

  .content-container-2 {
    max-width: none;
    overflow: visible !important;
  }

  #agenda-list > .agenda-card {
    padding: 0;
    border-radius: 0 !important;
    margin: 0px;
    padding-bottom: 0px;
    box-shadow: none !important;
    background-color: white !important;

    &:hover {
      transform: none !important;
    }
  }

  #schedule .agenda-card.Unconfirmed {
    background-color: transparent !important;
  }

  .agenda-card:not(:last-child) {
    border-bottom: none;
  }

  #agenda-list > .agenda-card:nth-child(even) {
    border-top: 2px solid #0096fa;
    border-bottom: 2px solid #0096fa;
  }

  .agenda-card > .case-body > div {
    font: normal 400 10px/18px 'open sans', arial, sans-serif !important;
  }

  .case-title {
    background-color: transparent !important;
    color: #000000 !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    padding: 0 !important;
    width: auto !important;
  }

  .readiness-badge {
    display: none !important;
  }

  #schedule
    .agenda-card
    .case-body
    > div:first-child
    p
    > span:first-child.Clinic {
    background-color: transparent !important;
  }

  .agenda-card span.case-title.Unconfirmed {
    color: #000000 !important;
  }

  #schedule .agenda-card .case-body .patient-details {
    width: 100%;
  }

  #schedule .agenda-card .case-body .patient-details span {
    margin: 0 !important;
  }

  .function-icons,
  .case-location-icon,
  .case-owner svg,
  .patient-details svg {
    display: none;
  }

  .case-owner {
    padding: 0 !important;
  }

  .event-agenda-content {
    height: auto !important;
  }

  .event-agenda-content p {
    line-height: 18px !important;
  }

  .event-agenda-content,
  .agenda-footer {
    display: block !important;
  }

  .case-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: fit-content(43px) 43px 1fr;
    grid-template-areas:
    "case-name time location"
    "patient-age status devices"
    "case-notes sch-notes devices";
    padding: 10px;
    color: #000000 !important;
    column-gap: 10px;
    align-items: start;
    font-size: 14px;

    p {
      font-size: 12.5px !important;
    }
  }

  /* case title */
  .event-agenda-content {
    grid-area: case-name;
    margin-top: 0 !important;
  }

  .event-agenda-content > .case-name {
    margin-top: 10px;
    width: 100% !important;
    word-break: break-word;
  }

  .event-agenda-content > .case-name:before {
    content: 'CASE NAME';
    color: #0096fa !important;
    display: block;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

  /* case time */
  .agenda-title {
    grid-area: time;
    font-size: 12.5px !important;
    display: block !important;

    span {
      line-height: normal !important;
      vertical-align: auto !important;
      color: #000000 !important;
      font-weight: 400 !important;
      font-size: 12.5px !important;

    }

    .case-icon-container {
      display: none;
    }
  }

  .agenda-title > p:before {
    content: 'TIME';
    color: #0096fa !important;
    display: block;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

  /* case owner */
  .event-agenda-content > .case-owner {
    display: none;
  }

  /* patient */

  .patient-details {
    margin-top: 0 !important;
    display: block;
  }

  .patient-details > p:nth-child(1) {
    grid-area: patient-age;
  }

  .patient-details > p:nth-child(1) > span:nth-child(2):before {
    content: 'PATIENT / AGE';
    color: #0096fa !important;
    display: block;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

  .patient-details > p:nth-child(1) > span:nth-child(2) {
    // display: block !important;
    width: 100% !important;
  }
  .patient-details > p:nth-child(1) > span:nth-child(3) {
    display: none !important;
  }

  /* patient status */
  .patient-details > p:nth-child(2) {
    display: none;
  }

  .print-status {
    display: block !important;
    align-items: center;
    grid-area: status;
    color: #000000 !important;
    font-weight: 400 !important;
    font-size: 12.5px !important;
  }

  .print-status:before {
    content: 'STATUS';
    color: #0096fa !important;
    display: block;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

  /* location */
  .agenda-footer {
    grid-area: location;
    margin-top: 0 !important;

    .agenda-location > span {
      margin: 0 !important;
      word-break: break-word;
    }
  }

  .agenda-location:before {
    content: 'LOCATION';
    color: #0096fa !important;
    display: block;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

  /** additonal case info for agenda print layout **/

  #Print_CaseNotes {
    grid-area: case-notes;
  }

  #Print_SchedulingNotes {
    grid-area: sch-notes;
  }

  #Print_Devices {
    grid-area: devices;
  }

  #Print_CaseNotes,
  #Print_SchedulingNotes,
  #Print_Devices {
    display: Block;
    font-size: 1rem !important;
    color: #000000 !important;
    overflow-y: hidden;
    margin-top: 0 !important;
  }

  div.Agenda_CaseNotesHeader,
  div.Agenda_SchedulingNotesHeader,
  div.Agenda_DevicesContainerHeader {
    letter-spacing: -0.3px;
    color: #0096fa !important;
    padding: 0 !important;
    font-size: 12.5px;
    font-weight: 600;
  }

  div.Agenda_CaseNotes,
  div.Agenda_SchedulingNotes {
    line-height: 1em !important;
    height: 2em;
    overflow: hidden;
    font-size: 12.5px;
  }

  div.Agenda_DevicesContainer {
    display: inline-block;
    line-height: 1em !important;
    height: 4em;
    overflow: hidden;

  }

  div.Agenda_Device {
    font-size: 12.5px;
  }


  /* INTERNET EXPLORER */
  _:-ms-lang(x),
  #detail {
    float: None !important;
    width: Auto !important;
  }

  _:-ms-lang(x),
  .AgendaContent > DIV:first-child > DIV:first-child > DIV:first-child {
    white-space: Normal !important;
  }
}
