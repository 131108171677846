#ds-main {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  width: 100vw;
  position: relative;
  background: transparent url('assets/img/HexBackground-White.svg') 0 20vh
    No-Repeat Fixed;
  font: Normal 300 1em 'Open Sans', Arial, Sans-Serif;
  cursor: Default;
  letter-spacing: -0em;
  overflow: auto;
  overflow-x: Hidden;
  font-size: 100%;
}

.page-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
