#calendar {
  > header {
    margin-top: 10px;

    > div:first-child,
    > div:last-child {
      border-radius: 50%;
      height: 30px;
      transition: var(--hover-transition);
      width: 30px;
      color: gray;

      &:hover {
        background-color: var(--light-gray);
        cursor: pointer;
        color: var(--black);
      }

      svg {
        transform: scale(1.15);
      }
    }

    > div:nth-child(2) {
      // border: 0.5px solid var(--icon-idle-gray);
      // border-radius: 8px;
      margin: 0 0.5rem;
      padding: 0.5rem;
      text-align: center;
      width: 225px;

      h1 {
        color: #50b6f5;
        font: Normal 600 24px/34px 'Open Sans', Helvetica, 'sans-serif';
        margin: 0 auto;
      }
    }
  }

  #view-today {
    color: var(--dark-blue);
    font: var(--dates-timestamp);
    font-weight: 600;
    height: 24px;
    margin: 0.5rem auto;

    > div {
      margin: 0 auto;
      transition: var(--hover-transition);
      width: fit-content;

      &:hover {
        color: var(--icon-active-gray);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  #weekdays {
    // border-bottom: 1px solid var(--light-gray);
    font-weight: 600;
    padding: 10px 0;
    color: #2c94e0;

    > div {
      width: 48px;
      text-align: center;
    }
  }

  #month {
    > .week {
      > .day {
        height: 48px;
        font-weight: 500;
        width: 48px;
        transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

        &.today {
          height: 48px;
          width: 48px;
          position: relative;
          // border: 2px solid var(--dark-red);
          border-radius: 100%;
        }

        &.today.selected {
          border: none;
        }

        // &.today{
        //   position: relative;
        //   &:after {
        //     content:'';
        //     position:absolute;
        //     bottom:-4px;
        //     left:0;
        //     right:0;
        //     background:var(--dark-blue);
        //     height:4px;
        //     border-radius: 10px;
        //   }
        // }

        > div {
          border-radius: 100%;
          transition: background-color 150ms ease-in-out,
            color 150ms ease-in-out;
          width: 35px;

          &.selected {
            background-color: var(--black) !important;
            border-radius: 30% !important;
            color: white !important;
            opacity: 1 !important;
          }

          &.today {
            box-shadow: inset 0 0 0 2px var(--dark-red), inset 0 0 0 3px white;
          }

          &.confirmed.selected:hover {
            background-color: var(--black) !important;
            cursor: pointer;
            color: white !important;
            box-shadow: inset 0 0 0 1px var(--calendar-confirmed),
              inset 0 0 0 3px white;
          }

          &.unconfirmed.selected:hover {
            background-color: var(--black) !important;
            color: white !important;
            box-shadow: inset 0 0 0 1px var(--calendar-unconfirmed),
              inset 0 0 0 3px white;
          }

          &.unconfirmed:hover {
            box-shadow: inset 0 0 0 1px var(--calendar-unconfirmed),
              inset 0 0 0 3px white;
          }

          &.confirmed:hover {
            box-shadow: inset 0 0 0 1px var(--calendar-confirmed),
              inset 0 0 0 3px white;
          }

          &.mid_vacation:hover,
          &.start_vacation:hover,
          &.end_vacation:hover,
          &.one_day_vacation:hover {
            box-shadow: inset 0 0 0 1px var(--calendar-vacation),
              inset 0 0 0 3px white;
          }

          &.one_day_conference:hover,
          &.mid_conference:hover,
          &.start_conference:hover,
          &.end_conference:hover {
            box-shadow: inset 0 0 0 1px var(--calendar-conference),
              inset 0 0 0 3px white;
          }

          &:hover {
            background-color: var(--light-gray) !important;
            cursor: pointer;
            color: var(--black) !important;
          }
        }
      }
    }
  }
}
