.pagination {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 18px;
  font: Normal 600 1em/36px, 'Open Sans', Arial, Sans-Serif;
  height: 36px;
  border-radius: 18px;
  box-shadow: 0 1px 10px 0 RGBa(0, 0, 0, 0.2);
  margin: 8px 20px 0 0;

  .prev-page,
  .next-page {
    width: 50px;
    height: 36px;
    color: White;
    box-shadow: Inset -6px 0 12px -6px RGBa(0, 0, 0, 0.3);
    cursor: pointer;
    background: #2f96f7;

    &.end {
      background: #b0bed0;
      cursor: no-drop;
      pointer-events: none;
    }

    &:hover {
      background: #007aff;
      color: White;
      font: Normal 400 1.4em/36px, 'Open Sans', Arial, Sans-Serif;
    }

    svg {
      height: 14px;
    }
  }

  .prev-page {
    border-radius: 18px 0 0 18px;
    box-shadow: Inset -6px 0 12px -6px RGBa(0, 0, 0, 0.3);

    > svg {
      margin: 11px 30px 11px 15px;
    }
  }

  .next-page {
    border-radius: 0 18px 18px 0;

    > svg {
      margin: 11px 15px 11px 30px;
    }
  }

  .pages {
    margin: 0 4px;
    width: 80px;
    background: White;
    color: Black;
    transition: All 0.12s Ease-In-Out;
    box-shadow: Inset 0 -40px 40px -45px RGBa(0, 0, 0, 0.4),
      0 1px 5px 0 RGBa(0, 0, 0, 0.2);
    border-radius: 18px;
    margin: 0 -18px;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 36px;
    justify-content: center;
    font: Normal 500 0.9em/36px 'Open Sans', Arial, Sans-Serif;

    &.loading {
      // background: url('../../../assets/files/Throbber-2C94E0.svg') no-repeat center center;
    }
  }
}
