.checklists-modal-body {
  overflow-y: auto;
  padding-left: 0.5rem;
  padding-right: 1rem;
  max-height: 550px;
  width: 700px;

  // patient name, age, gender
  > div:first-child {
    border-bottom: 1px solid var(--light-gray);

    > div:first-child {
      > p {
        margin: 0 0.5rem;
      }
    }

    > div:nth-child(2) {
      > div:first-child {
        font-weight: 500;
        border-radius: 50px;
        margin-right: 5px;
        margin-bottom: 5px;

        &.ready {
          width: fit-content;
          padding: 5px 15px;
          background-color: rgba(120, 223, 70, 0.8);
        }

        &.not-yet {
          padding: 5px 15px;
          width: fit-content;
          background-color: rgba(245, 211, 40, 0.8);
        }

        &.not-started {
          padding: 5px 15px;
          width: fit-content;
          background-color: rgba(239, 45, 55, 1);
        }

        &.unknown {
          display: none;
        }
      }
    }
  }

  .checklist-group {
    padding: 1rem 0;

    > label:first-child {
      margin-bottom: 0.5rem;
    }
  }
}
