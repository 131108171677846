#event-settings-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4;
}

#event-settings {
  background: var(--light-gray-bg);
  border-radius: 10px;
  box-shadow: 3px 3px 20px 5px #444444;
  height: calc(100vh - 120px);
  left: 50%;
  max-width: 1080px;
  padding-bottom: 2rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 200px);
  z-index: 5;

  header {
    background-color: rgba(82, 200, 231, 0.2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--black);
    margin: 0;
    padding: 1rem 2rem;

    > section:first-child {
      > img {
        height: 36px;
        width: 36px;
        margin-bottom: -6px;
      }
    }

    > section:nth-child(2) {
      flex-grow: 1;
      padding: 0 2rem;

      > h1 {
        margin: 0;
        padding-bottom: 0.5rem;
      }

      > h2 {
        margin: 0;
        font: normal 400 18px/22px 'Rubik', sans-serif;
      }
    }
  }

  > section {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 2rem;
  }

  h4 {
    margin: 0;
  }

  .tab-details {
    margin-bottom: 2rem;
  }

  .procedure-card {
    border-top: 1px solid var(--light-gray);
    padding: 2rem 0;
  }

  .location-card {
    border-top: 1px solid var(--light-gray);
    padding: 2rem 0;

    input.emr-location {
      background-color: var(--light-gray);
      cursor: no-drop;
      pointer-events: none;
    }
  }

  .checklist-group-card {
    border-top: 1px solid var(--light-gray);
    padding: 2rem 0;

    input[type='checkbox'] {
      height: auto;
      margin: -1px 0 0 0;
      width: auto;

      &:hover {
        cursor: pointer;
      }
    }

    .set-default-learn-more {
      padding-bottom: 1px;
      color: var(--dark-blue);
      font: var(--dates-timestamp);
      transition: var(--hover-transition);

      &:hover {
        color: var(--icon-active-gray);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  #tabs-container_event-settings {
    flex-grow: 1;
  }
}
