#docbox-print {
  display: none;
}

// docbox view print layout
@media print {
  body.docbox-open {
    background: var(--white);
    overflow: visible !important;
  }

  #docbox-print {
    display: block;

    #docbox-header {
      border-radius: 0;
      box-shadow: none;

      > section:first-child {
        align-items: flex-start;
        flex-direction: column;
        padding-right: 0;
        width: 100%;

        > div:nth-child(1) {
          margin-right: 0;

          p#case-title {
            max-width: none;
          }
        }

        > div:nth-child(2) {
          display: none;
        }

        > div:nth-child(3) {
          margin-top: 1rem;

          > div:first-child {
            margin-left: 0;
          }
        }
      }

      > section:last-child {
        display: none;
      }
    }

    #docbox-module-container {
      margin: 0;
      padding: 0;

      .docbox-module h1 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  body.docbox-open #ds-main {
    display: none !important;
  }
}
