#notification {
  background-color: var(--black);
  border-radius: 10px;
  bottom: 2rem;
  box-sizing: border-box;
  box-shadow: var(--box-shadow-card);
  color: var(--white);
  display: block;
  height: 62px;
  left: 2rem;
  padding: 16px;
  position: fixed;
  transition: 0.25s;
  min-width: 450px;
  max-width: calc(100vw - 50px);
  z-index: 100000;

  p {
    font-weight: 500;
  }
}
