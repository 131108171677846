.toggle {
  background-color: var(--icon-idle-gray);
  border: 0.5px solid var(--medium-gray);
  border-radius: 11px;
  display: flex;
  justify-content: flex-start;
  padding: 1px;
  min-width: 36px;
  height: 18px;
  transition: all 150ms ease-in;

  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 10px;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
    font-weight: 500;
  }

  .offText {
    color: white;
  }

  > div {
    background-color: var(--light-gray-bg);
    border-radius: 100%;
    height: 18px;
    width: 18px;
  }

  &.on {
    background: white;
    justify-content: flex-end;

    > div {
      background-color: var(--blueDocspera);
    }
  }
}

.toggle-large {
  margin-top: 12px;
  height: 24px;
  min-width: 75px;
  border-radius: 100px;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;

  span {
    font-size: 12px;
    margin: auto;
  }

  div {
    height: 22px;
    width: 22px;
  }
}
