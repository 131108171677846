.content-container {
  max-width: 100%;
  font: Normal Normal 14px/18px 'Open Sans', Helvetica, Arial, Sans-Serif;
  background: White url('../../assets/files/HexFlare.png') No-Repeat 95% -36px !important;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.6);
  min-height: 100%;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  background-color: white;

  .breadcrumb {
    margin: 0;
    list-style-type: none;
    padding: 8px 0px 8px 8px;
    background: linear-gradient(
      to right,
      rgba(167, 226, 249, 0.3) 0%,
      rgba(167, 226, 249, 0.3) 1%,
      rgba(167, 226, 249, 0) 45%,
      rgba(167, 226, 249, 0) 100%
    );

    li [class^='icon-'],
    li [class*=' icon-'] {
      line-height: 20px;
      display: inline-block;
      width: 1.25rem;
    }

    li {
      line-height: 20px;

      > a {
        color: rgb(102, 102, 102);
        text-decoration: none;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  #schedule-search {
    flex: 1 0 auto;
    padding-left: 30px;
    padding-right: 30px;

    > h1 {
      margin-top: 30px;
      font-size: 27px;
    }

    .search-grid {
      display: grid;

      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;

      p {
        color: #707071;
        margin: 0;
        font-size: 16px;
      }

      section {
        margin: 0 !important;

        form input:not([type='checkbox']) {
          height: 36px;
        }

        form input:not([type='checkbox']),
        form select,
        form textarea {
          border-radius: 5px;
          border-color: #50b6f5;
          padding-left: 45px;
          width: 90%;
        }

        .width90 {
          position: relative;

          .icon-search {
            position: absolute;
            top: 16px;
            left: 10px;
            color: #50b6f5;
            font-size: 23px;
          }
        }
      }

      .width20 {
        margin-top: 30px;
        text-align: right;
        color: rgb(102, 102, 102);
      }

      .toggle {
        color: white;

        > div {
          background-color: white;
        }
      }

      .toggle.on {
        background-color: #50b6f5;
        border-color: #50b6f5;

        > div {
          background-color: white;
        }
      }

      .toggle-large {
        min-width: 60px !important;
        width: 60px;
        background-color: #b1acac;
        border-color: #b1acac;

        span {
          font: normal normal bold 16px/22px 'Open Sans', Arial, Sans-serif;
        }
      }
    }
  }

  #schedule-search-results-container {
    margin: 30px 0;

    table {
      width: 100%;
      // border-bottom: 1px solid #6AB5F0;
      border-radius: 8px;
      border-spacing: 0px;
      border-top: none;

      thead {
        text-align: left;
        background-color: #a8dafa;
        font-size: 15px;

        th {
          padding: 10px 15px;
        }

        th:first-child {
          border-radius: 8px 0 0 0;
          background-color: #50b6f5;
          width: 65%;
          color: white;
          padding-left: 20px;
        }

        th:not(:first-child) {
          border-radius: 0 8px 0 0;
          width: 35%;
          color: black;
        }
      }

      tbody {
        tr {
          td {
            padding: 5px 20px;
            border: 1px solid #6ab5f0;
          }

          td:nth-child(odd) {
            border-bottom: none;
            border-right: none;
          }

          td:nth-child(even) {
            border-bottom: none;
            border-left: none;
            vertical-align: top;
            text-align: left;
            padding-top: 20px;
          }
        }

        tr:last-child {
          td:last-child,
          td:nth-last-child(2) {
            border-bottom: 1px solid #6ab5f0;
          }

          td:nth-last-child(2) {
            border-radius: 0px 0px 0px 8px;
          }

          td:last-child {
            border-radius: 0px 0px 8px 0px;
          }
        }
      }
    }
  }

  .agenda-card {
    background-color: #f7fdff;
    border-radius: 8px;
    box-shadow: var(--box-shadow-card);
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    transition: var(--hover-transition);

    &:hover {
      box-shadow: 0px 1px 8px 3px rgba(155, 155, 155, 1);
      cursor: pointer;
    }

    .case-color-tab {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      width: 30px;
    }

    .case-icon-container {
      padding-right: 1rem;

      > .case-icon {
        border-radius: 100%;
        height: 40px;
        left: 1rem;
        width: 40px;

        &.surgery {
          background: transparent
            url('../../assets/img/event-icons/surgical-icon.svg') 0% 0%
            no-repeat padding-box;
          border-color: RGBa(255, 255, 255, 1);
        }

        &.clinic {
          background: transparent
            url('../../assets/img/event-icons/clinical-icon.svg') 0% 0%
            no-repeat padding-box;
          border-color: RGBa(255, 255, 255, 1);
        }

        &.conference {
          background: transparent
            url('../../assets/img/event-icons/conference-icon.svg') 0% 0%
            no-repeat padding-box;
          background-size: cover;
          border-color: RGBa(255, 255, 255, 0.4);
        }

        &.vacation {
          background: transparent
            url('../../assets/img/event-icons/vacation-icon.svg') 0% 0%
            no-repeat padding-box;
          border-color: RGBa(255, 255, 255, 0.4);
        }
      }
    }

    .case-body {
      color: #707070;
      flex-grow: 1;

      > div:first-child {
        font: normal normal 600 15px/20px 'Open Sans', Arial, Sans-serif;

        p {
          span {
            color: white;
            background: #6ab5f0;
            border-radius: 100px;
            padding: 2px 10px;
            margin-left: -5px;

            &.Clinic {
              background: #ff9329;
            }

            &.Surgery {
              background: #00a1ff;
            }
          }
        }

        p.readiness {
          color: white;
          background: #6ab5f0;
          border-radius: 100px;
          padding: 1px 10px;
        }
      }

      > div:nth-child(2) {
        h1 {
          font-family: 'Open Sans', sans-serif;
          margin: 0;
          font-size: 1.1rem;
          font-weight: 600;
          margin: 0.75rem 0 !important;
        }

        p {
          margin: 0.75rem 0 !important;
        }

        .readiness-badge {
          border-radius: 7px;
          font: var(--body-text-small);
          font-weight: 500;
          margin: 0;
          padding: 0.1rem 0;
          width: 154px;

          > .readiness-icon {
            border-radius: 100%;
            height: 10px;
            margin-right: 0.5rem;
            margin-top: -2px;
            width: 10px;

            &.ready {
              background-color: var(--accent-green);
            }

            &.pending {
              background-color: var(--accent-yellow);
            }

            &.not-started {
              background-color: var(--accent-red);
            }
          }

          > p {
            font-size: 0.9rem;
            margin: 0;
            text-align: center;
          }
        }
      }

      > div:nth-child(3) {
        font-size: 15px;

        .case-location-icon {
          color: var(--medium-gray);
        }

        .case-owner {
          padding: 0.1rem 0.5rem;
          transition: var(--hover-transition);

          &.open {
            background-color: var(--light-gray-bg);
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
          }

          &:hover {
            background-color: var(--light-gray-bg);
            cursor: default;

            &:not(.open) {
              border-radius: 7px;
            }
          }
        }

        p {
          &.Confirmed {
            color: #65c44b;
          }

          &.Tentative {
            color: #ffaa00;
          }
        }
      }

      .all-attendees {
        background-color: var(--light-gray-bg);
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: 0.5rem;

        > div {
          width: 48%;

          h2 {
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
          }

          hr {
            margin: 0 0 0.25rem 0;
          }
        }
      }

      .patient-details {
        margin: 0.75rem 0 0 0;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
      }

      span {
        margin: 0 0.5rem;
      }
    }

    .confirm-tab {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 4px;
      width: 12px;
      overflow: hidden;

      // &.confirmed{
      //   background-color: var(--accent-light-blue);
      // }

      &.unconfirmed {
        background-color: var(--accent-yellow);
      }

      p {
        color: var(--icon-active-gray);
        font: var(--footer-text);
        font-weight: 700;
        margin: 0 0 0 -31px;
        padding: 0;
        text-transform: uppercase;
        transform: rotate(90deg);
      }
    }

    .invite-telehealth-disabled {
      opacity: 0.5;
    }
  }

  #docspera-footer {
    padding-left: 20px;
    padding-right: 20px;
    // position: fixed;
    // bottom:0;
    height: 30px;
    // width:95%;

    flex-shrink: 0;

    div {
      a {
        text-decoration: none;
        color: #666;
        cursor: pointer;
      }
    }

    div:nth-child(2) {
      a {
        padding-left: 10px;
      }
    }
  }
}
