#rep-cases {
  width: 100%;
  background: var(--white);
  min-height: 100%;
  height: 100%;
  font-family: Rubik;
  overflow: hidden;

  ol.docspera-table {
    margin-top: 100px;
    min-width: 1000px;
  }

  ol.docspera-table li.table-row {
    grid-template-columns: 11rem 1fr 1.5fr 1fr 1fr 1fr 1fr 16rem;
  }

  #location,
  #physician {
    padding: 10px 14px;
  }

  .pagination {
    border: none;
    font-family: Rubik;
    box-shadow: none;
    overflow: hidden;
  }

  .docspera-blue {
    color: var(--blueDocspera);
  }

  .disabled-nav {
    opacity: 0.4;
  }

  .header {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    position: fixed;
    left: 0;
    right: 0;
    background: var(--white);
    z-index: 5;

    .rep-title {
      font-weight: 900;
      font-size: 1.6rem;
    }

    form {
      background: var(--white);
      width: 100%;
      padding: 20px;
      padding-bottom: 0px;
    }
  }
}

.no-rep-cases {
  margin-top: 150px;
  margin-left: 50px;

  @media (max-width: 874px) {
    margin-top: 200px;
  }
  @media (max-width: 481px) {
    margin-top: 280px;
  }
  @media (max-width: 375px) {
    margin-top: 340px;
  }
}

// select {
//   cursor: pointer !important;
//   border: none;
//   color: white;
//   border-radius: 4px !important;
//   // box-shadow: Inset 0 0 3px 0 rgba(0, 0, 0, 0.47),
//   //   2px 1px 1px 0 rgba(255, 255, 255, 1), -2px 1px 1px 0 rgba(255, 255, 255, 1),
//   //   0 2px 1px 0 rgba(255, 255, 255, 1);
//   padding: 2px 5px;
//   font-family: 'Open Sans', sans-serif;
//   outline: none;
//   // background: linear-gradient(to bottom, #05a4f1 20%, #006ac9 80%) !important;
//   text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
// }

.input-container {
  position: relative;
  float: right;

  .search-input {
    margin-top: 24px;
  }
}

.search-icon {
  width: 30px;
  position: absolute;
  left: 2px;
  top: 27px;
}

.search-input {
  max-width: 175px;
  min-width: 50px;
  margin-top: 25px;
  padding-left: 30px;
}

.table {
  padding-left: 32px;
}

.table-header-span {
  font-family: var(--table-header);
  text-transform: uppercase;
}

.table-body {
  background-color: white;
  padding: 0px 10px 10px 10px;
}

.date-td {
  // min-width: 150px;

  // span {
  //   font-weight: 500;
  // }

  p {
    margin-top: 5px;
    font-size: 14px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: var(--icon-idle-gray);
    }
  }
}

.physician-td {
  // min-width: 130px;
  // span {
  //   font-weight: 500;
  // }
}

.case-td {
  // min-width: 160px;

  .patient-name {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: var(--icon-idle-gray);
    }
  }
}

.location-td {
  // min-width: 120px;
}

.readiness-td {
  // min-width: 160px;
}

.participants-td {
  // min-width: 150px;
}

.small-button {
  background-color: var(--blueDocspera);
}

button.btn-warning {
  background: #fe4d4d;
}

@media (max-width: 768px) {
  .rep-title {
    font-size: 16px;
  }
}

@media (max-width: 583px) {
  #rep-cases {
    overflow: auto;
  }
}

.rep-table {
  @media (max-width: 583px) {
    margin-top: 150px;
  }

  @media (max-width: 481px) {
    margin-top: 260px;
  }
  @media (max-width: 375px) {
    margin-top: 325px;
  }
}
