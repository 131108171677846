#docbox-header {
  background-color: var(--white);
  box-shadow: var(--box-shadow-card);
  border-radius: 10px;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;

  > section:first-child {
    flex-grow: 1;
    padding-right: 1rem;

    p {
      margin: 0;
    }

    > div:nth-child(1) {
      margin-right: 3rem;

      p {
        &#case-title {
          font: normal 500 20px/24px 'Rubik', sans-serif;
          color: var(--icon-active-gray);
          margin-bottom: 0.25rem;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &#patient-title {
          font: var(--legend-text);
          color: var(--icon-idle-gray);
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          > span:not(:first-child) {
            margin-left: 0.5rem;
          }
        }
      }
    }

    > div:nth-child(3) {
      > div {
        margin-left: 3rem;
      }

      p {
        &:first-child {
          font: var(--legend-text);
          color: var(--icon-idle-gray);
        }

        &:nth-child(2) {
          font: var(--dates-timestamp);
          color: var(--icon-active-gray);
          font-weight: 600;
        }
      }
    }
  }

  > section:nth-child(2) {
    > button {
      min-width: 36px;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:last-child {
        width: 36px;
      }
    }
  }
}
