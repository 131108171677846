#docbox-module-container {
  // background: var(--white);
  flex-grow: 1;
  margin: 0 0 1.5rem 15rem;
  padding-right: 1rem;
  // overflow-y: scroll;

  &.in-transition {
    transition: transform 750ms ease;
  }

  .docbox-module {
    background: var(--white);
    border-radius: 10px;
    margin: 0 0 1rem 0;
    min-height: 100px;
    padding: 0 0 1rem 0;

    h1 {
      background-color: rgba(82, 200, 231, 0.2);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: var(--black);
      font: normal 500 20px/24px 'Rubik', sans-serif;
      margin: 0;
      padding: 1rem 2rem;
    }

    h2 {
      color: var(--icon-active-gray);
      font: normal 500 20px/24px 'Rubik', sans-serif;
      margin: 0;
      padding: 2rem 0 0.5rem 2rem;

      > span {
        font-weight: 400;
        margin-left: 0.25rem;
      }

      b {
        font-weight: 400;
      }
    }

    h3 {
      font: normal 500 18px/20px 'Rubik', sans-serif;
      margin: 0;
      padding-top: 1rem;
    }

    p {
      line-height: 36px;
      margin: 0.5rem 0;
    }

    form div.form-row {
      margin-left: 4rem;
    }

    form input:not([type='checkbox']) {
      width: 98%;
    }

    &:last-child {
      margin: 0;
    }
  }

  #calendar-clashes {
    border: 2px solid var(--accent-red);
    border-radius: 10px;
    box-shadow: var(--box-shadow-card);
    margin: 1rem 2rem 0.5rem 4rem;
    padding: 0.5rem 2rem;

    li {
      list-style-type: none;

      > p {
        margin: 0;
      }
    }
  }
}

.docbox-module-banner {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 1rem 0;
  padding: 1rem;

  p {
    margin: 0.5rem auto;
  }
}

#checklists-container {
  margin: 1rem 0;
  // padding: 1rem 0 0.5rem 0;
}

#devices-container {
  margin: 1rem 0;
  // min-height: 70px;
}

// .input-field-icon{
//   bottom: 17px;
//   display: inline;
//   // float: right;
//   position: absolute;
//   right: 4px;
// }

#FIELD_start-date,
#FIELD_end-date,
#case-icd-bookmarks,
#case-cpt-bookmarks {
  cursor: pointer;
}

.attendee-card {
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 10px;
  box-shadow: var(--box-shadow-card);
  margin-top: 1rem;
  padding: 0.5rem 0;
  position: relative;

  > .btn-close {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  }

  .attendee-card-avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    border-radius: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2),
      inset 2px 4px 4px -2px RGBa(255, 255, 255, 0.2);
    height: 80px;
    margin: 0 0 0 1rem;
    width: 80px;
  }

  span {
    font: var(--dates-timestamp);
  }

  .attendee-invite-icon {
    border-radius: 100%;
    height: 10px;
    margin-right: 0.25rem;
    margin-top: -2px;
    width: 10px;

    &.Confirmed {
      background-color: var(--accent-green);
    }

    &.Invited {
      background-color: var(--accent-yellow);
    }

    &.Declined {
      background-color: var(--accent-red);
    }
  }
}

.device-row {
  margin: 1rem 0;

  button {
    margin-top: 1.25rem;
  }
}

.checklist-group {
  border-top: 1px solid var(--light-gray);
  padding: 2rem 0;
  // position: relative;

  // >button{
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  // }

  // .checklist-item-row:not(:last-child){
  //   border-bottom: 1px solid var(--light-gray);
  // }
}

p.clickable {
  &:hover {
    color: var(--dark-blue);
    cursor: pointer;
    text-decoration: underline;
  }
}

.icon-added {
  color: var(--accent-green);
}

.icon-not-added {
  opacity: 0.2;
}
