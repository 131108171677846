.filter-container {
  cursor: pointer !important;
  border: none !important;
  color: var(--white);
  border-radius: 4px !important;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.47),
    2px 1px 1px 0 rgba(255, 255, 255, 1), -2px 1px 1px 0 rgba(255, 255, 255, 1),
    0 2px 1px 0 rgba(255, 255, 255, 1);
  font-family: 'Open Sans', sans-serif;
  outline: none;
  background: linear-gradient(to bottom, #05a4f1 20%, #006ac9 80%) !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
  appearance: none;
  height: 36px;
  width: 250px;
  min-width: 250px;
  top: 8px;
  display: flex;
  justify-content: center;
  position: relative;

  &.not-null {
    width: unset;
    min-width: max-content;
  }

  .filter-box {
    .filter-name {
      color: var(--white);
      text-transform: uppercase;
      font-weight: 800;
      font-size: 16px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
      margin-right: 10px;
    }

    .selected-name {
      font: var(--body-text-small);
      line-height: 24px;
      &.not-null {
        margin-right: 20px;
      }
    }

    .select-icon {
      font-size: 15px;
      opacity: 0.6;
      margin-right: 10px;
      margin-left: 12px;
      vertical-align: -0.125em !important;
    }

    .select-arrow {
      filter: drop-shadow(0 1px 2px RGBa(0, 0, 0, 0.37));
      margin-right: 12px;
      vertical-align: -0.125em !important;
    }
  }
}

#filter-menu {
  .MuiPaper-root {
    background: linear-gradient(
      to bottom,
      White 40%,
      RGBa(220, 222, 224, 1) 150%
    );

    .menu-items-list {
      max-height: 410px;
      overflow: auto;

      .MuiMenuItem-root {
        color: var(--blueDocspera);

        .check-icon {
          color: var(--light-gray);
        }

        &:hover {
          background-color: rgb(0, 150, 250, 0.3);
          color: var(--blueDocspera);

          .check-icon {
            color: var(--blueDocspera);
          }
        }

        &.selected {
          color: var(--white);
          background-color: var(--blueDocspera);

          .check-icon {
            color: var(--white);
          }
        }
      }
    }
  }
}
