#all-cases-ksrc {
  width: 100%;
  background: var(--white);
  min-height: 100%;
  height: 100%;
  overflow: auto;
  font-family: 'Open Sans', Sans-Serif !important;

  #print-header,
  #print-table {
    display: none;
  }

  ol.ksrc-table li.table-row {
    min-width: 1000px;
    grid-template-columns: 8.5% 10% 8.5% 21% 9% 8% 9% 26%;

    .date-td {
      // min-width: 150px;

      span {
        font-weight: 400;
      }
    }

    .physician-td {
      // min-width: 130px;
      word-break: break-word;
      span {
        font-weight: 400;
      }
    }

    .case-td {
      font-weight: 400;

      > span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100vw / 5.5);
      }

      .patient-data {
        display: flex;
        justify-content: space-between;
        margin-top: 3px;

        .patient-name {
          margin-left: 5px;
          font-weight: 400;
        }

        span {
          color: var(--black) !important;
          font-size: 0.9em;
        }
      }
    }

    .location-td {
      word-break: break-word;
      font-weight: 500;
    }

    .status-td {
      display: inline-flex;
      align-items: center;
      // padding: -10px !important;
      // border-radius: 50px;
      justify-content: center;
      font-weight: 500;
      font-family: 'Open Sans', sans-serif;

      text-align: center;
      color: var(--black) !important;
      background-color: white;

      &.pre-op {
        background-color: rgba(239, 45, 55, 0.6);
      }

      &.ready-for-or {
        background-color: rgba(245, 211, 40, 0.7);
      }

      &.in-or {
        background-color: rgba(115, 250, 121, 0.7);
      }

      &.closing {
        background-color: rgba(255,147,41,0.7)
      }

      &.post-op {
        background-color: rgb(255, 192, 203, 0.7);
      }

      &.cancelled {
        background-color: var(--light-gray);
      }
    }

    .anesthesia-td,
    .block-td {
      font-weight: 500;
      font-family: 'Open Sans', sans-serif;
      color: var(--black) !important;
    }

    .participants-td {
      font-size: 12px;
      text-overflow: ellipsis;
      color: var(--black);
      font-weight: 400;

      .care-team {
        display: block;
        height: 31px;
        line-height: 1.2;
        overflow: hidden;
        white-space: normal;
        word-break: break-word;
      }
    }

    .notes-td {
      font-size: 12px;
      font-weight: 400;
      .notes-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        > span {
          width: 96%;
          overflow: hidden;
          white-space: normal;
          word-break: break-word;
          height: 31px;
          line-height: 1.2;
          text-overflow: ellipsis;
          max-width: calc(100vw / 4.3);
        }
      }
    }
  }

  .header {
    display: flex;
    margin-top: 70px;
    position: fixed;
    left: 0;
    right: 0;
    // background: rgba(220, 222, 224, 0.4);
    z-index: 5;
    white-space: pre-wrap;
    background: Linear-Gradient(
      to bottom,
      #ffffff 30%,
      rgba(220, 222, 224, 0.4) 120%
    );

    form {
      background: Linear-Gradient(
        to bottom,
        #ffffff 30%,
        rgba(220, 222, 224, 0.3) 120%
      );
      width: 100%;
      padding: 10px 0px 0 0px;

      &:hover {
        background: Linear-Gradient(
          to bottom,
          #ffffff 30%,
          rgba(179, 224, 246, 0.8) 120%
        );
      }

      #auto-scroll {
        margin: 0 15px !important;
        width: 120px !important;
        top: 11px;

        &[type='checkbox'].toggle {
          cursor: Pointer;
          display: Inline-Block;
          appearance: None !important;
          height: 30px !important;
          background: #ec5d57;
          position: Relative;
          border-radius: 30px !important;
          transition: All 0.2s ease-in-out;
          box-shadow: Inset 0 5px 10px 0 RGBa(0, 0, 0, 0.05) !important;
          border: 1px Solid RGBa(0, 0, 0, 0.14);
          -webkit-appearance: None !important;
          -moz-appearance: None !important;
          -ms-appearance: None !important;
        }

        &[type='checkbox']::before {
          content: attr(data-label);
          color: White;
          font: Normal 800 10px/26px 'Open Sans', Arial, Sans-Serif;
          display: Inline-Block;
          text-align: Center;
          top: 1px;
          width: calc(100% - 26px);
          left: 22px;
          position: Absolute;
          transition: Left 0.1s Ease-In-Out;
          text-shadow: 0 1px 2px RGBa(0, 0, 0, 0.3);
          font-size: 13px;
          -webkit-text-stroke: 0.4px;
          letter-spacing: 0.05px;
        }

        &[type='checkbox'].toggle::after {
          content: '';
          font: Normal Normal 1em/26px, 'Open Sans', Arial, Sans-Serif;
          color: RGBa(0, 0, 0, 0.2);
          text-align: Center;
          display: Inline-Block;
          position: Absolute;
          width: 26px;
          height: 26px;
          background: White;
          top: 1px;
          left: 1px;
          border-radius: 100%;
          transition: All 0.1s ease-in-out;
          box-shadow: 0 2px 8px 0 RGBa(0, 0, 0, 0.2),
            Inset 1px 1px 0 0 RGBa(255, 255, 255, 1),
            Inset -6px -6px 26px -14px RGBa(0, 0, 0, 0.5) !important;
        }

        &[type='checkbox']:checked::before {
          left: 3px;
        }

        &[type='checkbox']:checked.toggle::after {
          left: calc(100% - 28px);
        }

        &[type='checkbox']:checked.toggle {
          background: #04c265;
        }
      }

      .auto-scroll-icon {
        &.windows {
          left: 120px !important;
        }
      }

      .calendar-picker-container {
        #pd-day {
          .fa-calendar-alt {
            left: 12px;
          }
        }
      }
    }
  }
}

.no-cases {
  margin-top: 200px;
  margin-left: 50px;

  @media (max-width: 928px) {
    margin-top: 270px;
  }
  @media (max-width: 466px) {
    margin-top: 430px;
  }
  // @media (max-width: 375px)  {
  //   margin-top: 270px;
  // }
}

.labelAndDropdown {
  flex-direction: column;
  padding-right: 15px;
  padding-bottom: 10px;
  position: relative;
  display: flex;
  letter-spacing: -0.3px;

  &:first-child {
    padding-left: 20px;
  }

  label {
    color: white;
    position: absolute;
    top: 17px;
    font-weight: 800;
    font-size: 16px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
  }

  label {
    left: 35px;
  }

  .select-icon {
    position: absolute;
    top: 16px;
    left: 12px;
    font-size: 15px;
    color: white;
    opacity: 0.6;
    margin-top: 2px;
  }

  .select-arrow {
    position: absolute;
    top: 16px;
    right: 30px;
    color: white;
    filter: drop-shadow(0 1px 2px RGBa(0, 0, 0, 0.37));
    z-index: 10;
  }
}

select {
  cursor: pointer !important;
  border: none !important;
  color: white;
  border-radius: 4px !important;
  box-shadow: Inset 0 0 3px 0 rgba(0, 0, 0, 0.47),
    2px 1px 1px 0 rgba(255, 255, 255, 1), -2px 1px 1px 0 rgba(255, 255, 255, 1),
    0 2px 1px 0 rgba(255, 255, 255, 1);
  padding: 2px 5px;
  font-family: 'Open Sans', sans-serif;
  outline: none;
  background: linear-gradient(to bottom, #05a4f1 20%, #006ac9 80%) !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.37);
  appearance: none;

  &#type {
    padding-left: 86px;
    width: 175px;
    &.changed-type {
      width: 200px;
    }
  }

  &#location {
    padding-right: 30px;
    padding-left: 126px;
    width: 250px;

    &.changed-loc {
      width: min-content;
    }
  }

  &#physician {
    padding-right: 30px;
    padding-left: 130px;
    width: 250px;
    &.changed-phys {
      width: 300px;
    }
  }

  option {
    color: black;
    background-color: #f7f8fb;
  }
}

.input-container {
  position: relative;
  float: right;

  .search-input {
    margin-top: 24px;
  }
}

.search-icon {
  width: 30px;
  position: absolute;
  left: 2px;
  top: 27px;
}

.search-input {
  max-width: 175px;
  min-width: 50px;
  margin-top: 25px;
  padding-left: 30px;
}

.table {
  padding-left: 32px;
}

.table-header-span {
  font-family: var(--table-header);
  text-transform: uppercase;
}

.table-body {
  background-color: var(--white);
  padding: 0px 10px 10px 10px;
}

.small-button {
  background-color: var(--blueDocspera);
}

button.btn-warning {
  border-radius: 5px;
  background: var(--white);
  color: var(--blueDocspera);
  border: 1px solid var(--blueDocspera);
  margin-right: 10px !important;
  height: 32px;
  padding: 0.2rem 1.3rem;

  &:hover {
    background-color: var(--blueDocspera) !important;
    border: 1px solid var(--blueDocspera) !important;
  }
}

button.btn-success {
  border-radius: 5px;
  height: 32px;
  padding: 0.2rem 1.3rem;

  &:hover {
    background-color: var(--blueDocspera) !important;
    opacity: 0.5;
  }
}

.table-container {
  margin-top: 143px;

  .pd-loading {
    background-size: 12em 12em;
    width: 100%;
    height: 12em !important;
    text-align: Center;
    letter-spacing: -0.5px;
    cursor: Wait !important;
    font: Normal 700 0.98em/12.4em 'Open Sans', Arial, Sans-Serif !important;
    color: #2c94e0;
    margin-top: 100px;
    background: url('../../../assets/files/Throbber-2C94E0.svg') no-repeat
      center center;
  }

  @media (max-width: 481px) {
    margin-top: 330px;
  }
  @media (max-width: 466px) {
    margin-top: 410px;
  }
}

@media (max-width: 928px) {
  .global-nav-header,
  #all-cases-ksrc .header,
  ol.ksrc-table {
    zoom: 80%;
  }

  .table-container {
    margin-top: 0px;
  }

  ol.ksrc-table {
    margin-top: 129px;
  }
  #search-bar-field {
    width: 100px;
  }
}

//model styling
.MuiPaper-root {
  &.MuiAccordion-root {
    border: 1px solid var(--blueDocspera);
    border-radius: 3px !important;
    box-shadow: none;

    .MuiAccordionSummary-gutters {
      min-height: 38px;

      .MuiAccordionSummary-content {
        margin: 0px;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0px 0px 8px;
      .MuiMenuItem-root {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }
}

@media print { 
  /** for web_master **/
  #SideBar {
    display: none !important;
  }
  /** for web_master **/

  .global-nav-header,
  #all-cases-ksrc > .content.header {
    display: none;
  }

  #all-cases-ksrc {
    #print-header {
      display: block;
    }

    .table-container {
      display: none;
    }

    #print-table {
      display: block;
      margin-top: 20px;

      .ksrc-table {
        margin-top: 0px;

        .table-row {
          grid-template-columns: 5% 10% 8.5% 20% 10% 10% 11% 25.5%;
          background-color: rgba(255, 255, 255, 0.5) !important;

          .ksrc-th {
            background-color: rgba(255, 255, 255, 0.5);
            color: var(--black);
            border: none;
            padding: 5px 10px;

            &.active {
              background-color: rgba(255, 255, 255, 0.5) !important;
              color: var(--black);
            }

            &:first-child {
              border-left: none;
            }

            &:not(:last-child) {
              border-right: 1px solid var(--light-gray) !important;
              margin-right: 1px;
            }

            div > svg {
              display: none;
            }
          }

          .ksrc-td {
            padding: 10px 10px;

            &:not(:last-child) {
              border-right: 1px solid var(--light-gray) !important;
              margin-right: 1px;
            }

            .case-td {
              display: inline-block;

              > span {
                white-space: normal;
                display: revert;
                max-width: 100%;
                overflow: initial;
                margin-right: 5px;
                word-break: break-word;
              }

              .patient-data {
                margin-top: 0;
                display: inline-block;

                .patient-name {
                  margin-left: 0;
                }

                span {
                  font-size: 1em;
                }
              }
            }

            .status-td {
              background-color: rgba(255, 255, 255, 0.5) !important;
              display: block;
              text-align: left;
              text-transform: uppercase;
            }

            .location-td {
              word-wrap: break-word;
              word-break: normal;
              width: 100%;
            }

            .notes-container {
              > span {
                width: 100%;
                height: 30px;
                max-width: 100%;
              }

              .tiny-button {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
