#calendar-container {
  // background-color: var(--white);
  // border-radius: 10px;
  // box-shadow: var(--box-shadow-card);
  // height: 675px;
  padding-right: 1.5rem;
  padding-top: 0px;
  width: 350px;
}

#calendar-legend {
  font: var(--legend-text);
  margin-top: 5rem;

  h2 {
    color: var(--medium-gray);
    font: var(--legend-text);
    margin: 0.25rem auto;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 5px;
    border-bottom: 1px solid gray;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 60%;

    li {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      margin: 0.5rem auto;
    }

    div {
      border-radius: 100%;
      margin-right: 6px;
    }
  }
}

#clinical-show-hide {
  margin-top: 1rem;
  justify-content: center;

  label {
    width: 275px;
    text-align: center;
    font: var(--dates-timestamp);
    font-weight: 600;
    background: rgba(255, 147, 41, 1);
    border: None !important;
    border-radius: 37px !important;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    height: 34px;
    outline: None;
    transition: 0.4s ease-in-out;
    line-height: 34px;

    > svg {
      margin-right: 10px;
    }

    &.clinical-shown {
      color: white;
      background-color: rgba(255, 147, 41, 1);
    }

    &.clinical-hidden {
      background-color: #00a1ff;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .toggle.on > div {
    background-color: rgba(255, 147, 41, 1) !important;
  }
}
