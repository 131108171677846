.billing-code-card {
  padding: 1rem 0;

  > :nth-child(2) {
    padding-right: 2rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--light-gray);
  }
}
