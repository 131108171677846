#global-nav-menu {
  padding: 0;
  width: 280px;
  font-size: 14px;
  color: var(--white);
  text-shadow: 1px 1px 2px rgba($color: #000000, $alpha: 0.3);

  h3 {
    margin: 0;
    padding: 0.5rem 0 0.5rem 2rem;
    font-weight: 400;
    border-top: 1px solid RGBa(0, 0, 0, 0.1);

    &:hover {
      cursor: pointer;
      background: #12618f;
    }

    .arrow-icon {
      color: #1b8fd3;
    }

    span {
      margin-right: 15px;
    }
  }

  h3.selected {
    background: #12618f;
    position: relative;
  }

  h3.selected::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 20%;
    z-index: 2;
    width: 8px;
    height: 25px;
    background: url('../assets/files/sidebar-menu-arrow.png') no-repeat center
      center;
  }

  ul {
    list-style-type: none;
    margin: 0.5rem 0;
    padding: 0;

    li {
      font-weight: 400;
      margin: 0 0 0 1.5rem;
      padding: 0.35rem 1.35rem 0.35rem 1.5rem;

      &:hover {
        cursor: pointer;
        background: #85ccf8;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
    }

    li.selected {
      background: #1b8fd3;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
  }

  li.selected {
    background: #1b8fd3 !important;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  hr {
    border-color: var(--light-gray-bg);
    margin: 0 0 0 2rem;
  }

  .nav-list {
    display: none;

    &.active {
      display: block;
      // background-color: #1b8fd3;
    }
  }

  .nav-link {
    transition: var(--hover-transition);

    &.selected {
      background-color: #12618f;
      color: var(--white);
    }

    &.shared {
      color: var(--dark-blue);

      &.selected {
        color: var(--white);
      }
    }
  }

  #pending-count {
    border-radius: 10px;
    background-color: var(--accent-yellow);
    color: var(--black);
    font: var(--dates-timestamp);
    height: 22px;
    line-height: 22px;
    padding: 0 0.25rem;
    width: fit-content;
  }
}

.global-nav-header {
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;

  nav {
    ul {
      display: flex;
      list-style: none;
      margin: 0px;
      padding: 0px;
      height: 100%;
      padding: 0px 0px 0px 10px;
      background: linear-gradient(
          to right,
          rgba(179, 224, 246, 1) 20%,
          rgba(179, 224, 246, 0.3) 100%
        )
        no-repeat 100% 50%;
      position: relative;
      justify-content: space-between;
      align-items: center;

      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: white;
        z-index: -1;
      }

      li {
        text-align: center;
        width: 160px;
        height: 60px;
        display: Inline-Block;
        cursor: Pointer;
        text-decoration: None;
        text-align: Center;
        padding: 0px 10px 0px 10px;
        margin: 10px 10px 0 5px;
        transition: All 0.2s ease-in-out;
        border-radius: 5px 5px 0 0;
        overflow-y: hidden;

        &:hover {
          background: #2eabe7;
          transition: All 0.2s ease-in-out;

          h3,
          p {
            color: white !important;
          }
        }

        h3 {
          font: Normal 700 13px/16px FontAwesome, 'Open Sans', Arial, Sans-Serif;
          text-transform: UpperCase;
          color: #0098e2;
          margin-top: 1em;
          letter-spacing: -0.4px;
          width: 96%;
          height: 1em;
          display: Inline-Block;
          overflow: Hidden;
          white-space: NoWrap;
          text-overflow: Ellipsis;
          margin-bottom: -5px;
        }

        p {
          font: Normal 400 12px/16px 'Open Sans', Arial, Sans-Serif;
          color: Black;
          margin-top: 0.5em;
          text-transform: capitalize;
          letter-spacing: -0.4px;
          width: 96%;
          height: 1.2em;
          display: Inline-Block;
          overflow: Hidden;
          white-space: NoWrap;
          text-overflow: Ellipsis;
          letter-spacing: -0.2px;
        }
      }

      .header-functions {
        display: flex;
        align-items: center;

        #search-bar {
          position: relative;
          margin-right: 15px;

          .search-icon {
            position: absolute;
            top: 16px;
            left: 10px;
            color: var(--icon-idle-gray);
            font-size: 23px;
          }

          #search-bar-field {
            border: 2px Solid rgba(0, 0, 0, 0);
            background: rgba(255, 255, 255, 0.6);
            box-shadow: 0 0 20px rgba(0, 152, 226, 0.2);
            transition: all 1s ease-in-out, width 0.3s ease-in-out,
              border 0.4s ease-in-out;
            width: 125px;
            border-radius: 40px;
            padding-left: 35px;

            &:focus {
              border: 2px Solid #29abf7;
              background: rgba(255, 255, 255, 1);
              animation: FocusBarSearchField 2s infinite ease-in;
              width: 205px;
            }

            @keyframes FocusBarSearchField {
              0% {
                box-shadow: 0 0 50px rgba(0, 152, 226, 0.4),
                  120px 0 100px rgba(0, 152, 226, 1),
                  240px 0 200px rgba(0, 152, 226, 1);
              }

              50% {
                box-shadow: 0 0 100px rgba(0, 152, 226, 1),
                  120px 0 200px rgba(0, 152, 226, 1),
                  240px 0 300px rgba(0, 152, 226, 1);
              }

              100% {
                box-shadow: 0 0 50px rgba(0, 152, 226, 0.4),
                  120px 0 100px rgba(0, 152, 226, 1),
                  240px 0 200px rgba(0, 152, 226, 1);
              }
            }
          }
        }

        .print-btn {
          width: 80px;
          height: 73.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--blueDocspera);
          cursor: pointer;

          svg {
            transition: fill 200ms ease-in-out 0ms;
          }

          &:hover {
            svg {
              transform: scale(1.2);
              transition-property: transform;
            }
            background-color: var(--blueDocspera);
            color: var(--white);
          }
        }
      }
    }
  }
}

li.selected {
  background: white;
}
