.schedule-container {
  display: block;
  width: 100%;
  font: Normal Normal 14px/18px 'Open Sans', Helvetica, Arial, Sans-Serif !important;
  background: White url('../../assets/files/HexFlare.png') No-Repeat 95% -36px !important;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.6);
  min-height: 100%;
  margin-bottom: auto;
  background-color: white;
  // padding: 0 4rem;

  #calendar-subheader {
    display: flex;
    padding: 1.25rem 30px;
    margin-top: -1rem;

    > div {
      margin-top: 1rem;
    }

    > div:first-child {
      #calendar-view-toggler {
        // border: 1px solid #B3B3B3;
        font: var(--dates-timestamp);
        font-weight: 600;
        margin-right: 2rem;
        border: 0.5px solid var(--icon-idle-gray);
        border-radius: 4px;

        > div {
          background-color: var(--white);
          display: flex;
          justify-content: center;
          padding: 0.75rem 0;
          width: 120px;

          &.selected {
            background-color: #0096fa;
            color: var(--white);
            box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 47%),
              2px 1px 1px 0 rgb(255 255 255), -2px 1px 1px 0 rgb(255 255 255),
              0 2px 1px 0 rgb(255 255 255);
            background: linear-gradient(
              to bottom,
              #05a4f1 20%,
              #006ac9 80%
            ) !important;
            text-shadow: 0 1px 2px rgb(0 0 0 / 37%);
          }

          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      #week-selector {
        > div:first-child,
        > div:last-child {
          border-radius: 6px;
          height: 30px;
          transition: var(--hover-transition);
          width: 30px;

          &:hover {
            background-color: var(--light-gray);
            cursor: pointer;
          }
        }

        svg {
          transform: scale(1.5);
        }

        #week {
          background-color: var(--white);
          border: 0.5px solid var(--icon-idle-gray);
          border-radius: 8px;
          font: var(--selector-text);
          padding: 0.75rem 0;
          margin: 0 0.5rem;
          width: 250px !important;
          text-align: center;
          letter-spacing: -0.3px;
          justify-content: center;

          > svg.select-arrow {
            display: none;
          }
        }

        .calendar-picker-popover {
          left: 305px;
          top: 80px;
        }
      }

      .dropdown-box {
        position: relative;

        #calendar-selector {
          position: relative;
          background-color: var(--white);
          border-radius: 8px;
          font: var(--selector-text);
          margin-left: 2rem;
          padding: 0.75rem;
          width: 225px;
          appearance: none;
          z-index: 1;
          padding-left: 35px;

          option {
            color: black;
            background-color: #f7f8fb;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .select-user {
          position: absolute;
          right: 200px;
          top: 15px;
          color: white;
          filter: drop-shadow(0 1px 2px RGBa(0, 0, 0, 0.37));
          z-index: 10;
          opacity: 0.6;
        }

        .select-arrow {
          position: absolute;
          left: 232px;
          top: 12px;
          color: white;
          filter: drop-shadow(0 1px 2px RGBa(0, 0, 0, 0.37));
          z-index: 10;
        }
      }
    }

    > div:nth-child(2) {
      > div {
        color: #4d90fe;
        font-weight: 500;

        > svg {
          height: 18px;
          // margin-right: 0.5rem;
          width: auto;
        }

        &:hover {
          color: #0362fd;
          cursor: pointer;
          text-decoration: underline;
        }

        &:not(:last-child) {
          padding-right: 1.5rem;
          // border-right: 0.5px solid var(--icon-idle-gray);
        }

        &:not(:first-child) {
          padding-left: 1.5rem;
        }
      }

      > div.print-calendar,
      > div.add-calendar,
      > div.event-settings {
        background: none repeat scroll 0 0 #4d90fe;
        border-radius: 20px !important;
        color: white;
        display: inline-block;
        // font-size: 18px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        vertical-align: middle !important;
        width: 36px;
        padding: 0px !important;
        margin-right: 10px;

        &:hover {
          cursor: pointer !important;
          background-color: #0362fd !important;
        }
      }

      > div.create-event {
        background-color: #4d90fe;
        border: medium none !important;
        border-radius: 37px !important;
        color: white;
        font-size: 16px;
        padding: 8px 14px;
        width: 100px;
        text-align: center;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;

        &:hover {
          cursor: pointer !important;
          background-color: #0362fd !important;
          text-decoration: none !important;
        }
      }

      .icon-airplane {
        svg {
          color: #0098e2;
          cursor: pointer;

          &:hover {
            color: white;
          }
        }
      }

      .airplane-number {
        background-color: #fe4d4d;
        position: absolute;
        top: -12px;
        left: -14px;
        border-radius: 100px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: white;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }

  #schedule {
    margin-top: 0;
    flex-grow: 1;
    max-width: 100%;
    padding: 0 30px;
  }

  #modal-overlay {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
  }

  #vacation-conference-modal {
    background: var(--light-gray-bg);
    border-radius: 10px;
    box-shadow: 3px 3px 20px 5px #444444;
    left: 50%;
    height: fit-content;
    max-width: 900px;
    padding-bottom: 2rem;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 200px);
    z-index: 5;

    header {
      background-color: rgba(82, 200, 231, 0.2);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      color: var(--black);
      margin: 0;
      padding: 1rem 2rem;

      > section:first-child {
        flex-grow: 1;
        padding: 0;

        > h1 {
          margin: 0;
          padding-bottom: 0.5rem;
        }

        > h2 {
          margin: 0;
          font: normal 400 18px/22px 'Rubik', sans-serif;
        }
      }
    }

    .event-type-selector {
      align-items: center;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 110px;
      width: 128px;
      background-color: var(--light-gray-line);
      border: 1px solid #dfdfdf;

      p {
        margin: 0.5rem 0 0 0;
        font-weight: 500;
      }

      &.selected {
        background-color: #52c8e733;
        border: 1px solid var(--velys-blue);
      }
    }

    label.view-mode {
      color: #707070;
      line-height: 0.25rem;
    }
  }
}

@media print {
  div#root > header,
  div#root > footer,
  #global-nav-menu,
  .schedule-container > #calendar-subheader,
  .schedule-container .content-container-2 > .tabs,
  #calendar-container {
    display: none;
  }

  #ds-main,
  #schedule {
    padding: 0 !important;
    overflow: visible !important;
  }

  #ds-main {
    background: var(--white);
    height: auto !important;
    position: static !important;
  }

  .content-container-2 {
    max-width: none;
    overflow: visible !important;
  }

  .fc .fc-view-harness {
    position: static !important;
  }

  #schedule
    > div
    > div
    > div.fc-view-harness.fc-view-harness-active
    > div
    > table
    > tbody
    > tr
    > td
    > div
    > div
    > div
    > div.fc-timegrid-cols
    > table {
    // width: auto !important;
    margin: 0 !important;
  }

  body,
  #schedule
    > div
    > div
    > div.fc-view-harness.fc-view-harness-active
    > div
    > table
    > tbody
    > tr
    > td
    > div
    > div,
  #schedule
    > div
    > div
    > div.fc-view-harness.fc-view-harness-active
    > div
    > table
    > thead
    > tr {
    // zoom: 80% !important;
    margin: 0% !important;
    display: block !important;
    width: auto !important;
    height: auto !important;
    position: relative !important;
    overflow: visible !important;
    // padding-right: 30px !important;
    background-color: white !important;
    // box-sizing: none !important;
  }
  #schedule .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard,
  #schedule > div > div > div.fc-view-harness.fc-view-harness-active {
    height: auto !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    width: 100% !important;
  }

  #vacation-conference-modal,
  #modal-overlay {
    display: none;
  }

  // for IE: remove scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
}

div.Loading {
  background: #fcfcfc url('../../assets/files/loading.gif') No-Repeat;
  width: 128px;
  height: 128px;
  text-align: Center;
  font: Normal Normal 18px/128px 'Open Sans', Helvetica, Arial !important;
  color: #50b6f5;
  margin: 100px Auto;
  border: 1px Solid #eee;
  border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  -moz-box-shadow: Inset 0 0 10px #fff;
  -webkit-box-shadow: Inset 0 0 10px #fff;
  box-shadow: Inset 0 0 10px #fff;

  &:after {
    content: 'Loading';
  }
}
