.physicians-schedule {
  display: flex;
  width: 100%;
  margin-top: 120px;
  overflow: hidden;

  header {
    z-index: 9;
  }

  .header {
    display: flex;
    width: 100%;
  }

  .airplane-number {
    background-color: var(--accent-red);
    position: absolute;
    top: -13px;
    left: -5px;
    border-radius: 100px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: white;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .schedule-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.physician-schedule-subheader {
  display: flex;
  top: 70px;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  z-index: 999;
  left: 0;
  right: 0;
  background: Linear-Gradient(
    to bottom,
    #ffffff 30%,
    rgba(220, 222, 224, 0.3) 120%
  );

  &:hover {
    background: Linear-Gradient(
      to bottom,
      #ffffff 30%,
      rgba(179, 224, 246, 0.8) 120%
    );
  }

  .btn-success {
    background: var(--blueDocspera);
    color: var(--white);
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;

    &:hover {
      background: #0098e2;
    }
  }

  .icon-airplane {
    svg {
      color: #0098e2;
      cursor: pointer;
    }
  }
}
