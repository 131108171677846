#docbox-overlay {
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

#docbox {
  background: var(--light-gray-bg);
  border-radius: 10px;
  box-shadow: 3px 3px 20px 5px #444444;
  height: calc(100vh - 100px);
  left: 50%;
  max-width: 1160px;
  overflow: hidden;
  padding: 1rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 100px);
  z-index: 99999;

  &.show-aside {
    max-width: 1280px;
  }

  #docbox-delete-case {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    width: 180px;
  }
}

#docbox-body {
  flex-grow: 1;
  max-height: 100%;
  overflow-y: scroll;
  position: relative;
  margin-top: 85px;
}

#docbox-nav {
  // background: var(--white);
  // border-radius: 10px;
  // box-shadow: 0px 3px 6px rgba(0,0,0,0.2);
  margin: 0 1rem 1rem 0;
  padding: 0 1rem;
  position: fixed;
  width: 180px;
  max-height: calc(100% - 170px);
  overflow: auto;

  h1,
  h2 {
    border-radius: 10px;
    font: normal 400 16px/18px 'Rubik', sans-serif;
    margin: 0.25rem 0;
    padding: 0.25rem 0 0.25rem 0.5rem;
    text-transform: capitalize;
    transition: var(--hover-transition);

    &:hover {
      cursor: pointer;
    }
  }

  h1 {
    font-weight: 500;

    &:hover {
      background: rgba(20, 120, 180, 0.2);
      color: var(--black);
    }

    &.active {
      background: var(--dark-blue);
      color: var(--white);
    }
  }

  h2 {
    margin-left: 1rem;

    &:hover {
      background: rgba(20, 120, 180, 0.2);
      color: var(--black);
    }

    &.active {
      background: var(--dark-blue);
      color: var(--white);
      font-weight: 500;
    }
  }
}

#docbox-aside {
  background: var(--white);
  border-radius: 10px;
  margin: 0 1rem;
  min-width: 180px;
  max-width: 180px;
  padding: 1rem;

  > div.aside-module:not(:last-child) {
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }
}

// docbox print layout
@media print {
  #docbox,
  #docbox-overlay {
    display: none;
  }
}
