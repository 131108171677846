#weekly-calendar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: var(--box-shadow-card);
  padding-left: 60px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-left: 30px;
  border-radius: 10px 10px 0 0;

  .day {
    width: 100%;
    border-bottom: 1px solid var(--medium-gray);
  }

  .today {
    border: 3px solid var(--blueDocspera);
    border-radius: 10px;
    margin-top: -3px;
  }

  span {
    color: black;
    font-weight: 500;
    font-size: 14px;
  }

  .day-header {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
  }

  .time-on-left {
    position: absolute;
    left: -45px;
  }

  .quarter {
    position: relative;
    border-top: 1px solid var(--medium-gray);
    border-left: 1px solid var(--medium-gray);
    height: 20px;
    z-index: 0;
    user-select: none;

    .hover-time {
      color: transparent;
      margin-left: 5px;
      font-weight: 400;
    }

    &:hover {
      .hover-time {
        color: black;
        cursor: pointer;
      }
    }
  }

  .hour {
    position: relative;
    border-top: 1px solid var(--medium-gray);
    border-left: 1px solid var(--medium-gray);
    height: 50px;
    user-select: none;

    .hover-time {
      color: transparent;
      margin-left: 5px;
      font-weight: 400;
    }

    &:hover {
      .hover-time {
        color: black;
        cursor: pointer;
      }
    }
  }

  .quarter-of-today {
    border-top: 1px solid var(--medium-gray);
    border-left: 0;
  }

  .quarter-of-tomorrow {
    border-left: 0;
  }

  .quarter-of-saturday {
    border-right: 1px solid var(--medium-gray);
  }

  .odd {
    background-color: var(--lighter-blue);
    z-index: 0;
  }

  .even {
    background-color: transparent;
    z-index: 0;
  }

  .selecto-area .selected {
    background-color: #a5d3df;
  }

  .selecto-area .avail-selected {
    background-color: #aabd9a;
  }

  .available {
    background-color: #d3e8c0;
  }

  .event-box {
    width: 100%;
    height: 82px;
    background-color: white;
    border: 1px solid blue;
    z-index: 9999;
    border-radius: 5px;
  }
}
