.tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  // padding-top: 15px;

  > li {
    padding: 0.5rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &.selected {
      border-bottom: 0.4rem solid var(--blueDocspera);
    }

    &:hover {
      border-bottom: 0.4rem solid var(--blueDocspera);
      cursor: pointer;
      opacity: 0.7;
    }
  }

  // > li{
  //   padding-bottom: 0.5rem;

  //   &:not(:last-child){
  //     margin-right: 2.5rem;
  //   }

  //   &.selected{
  //     font-weight: 500;
  //     position: relative;
  //     &:after {
  //       content:'';
  //       position:absolute;
  //       bottom:0;
  //       left:0;
  //       right:0;
  //       background:var(--velys-blue);
  //       height:4px;
  //       border-radius: 10px;
  //     }
  //   }

  //   &:hover{
  //     position: relative;
  //     &:after {
  //       content:'';
  //       position:absolute;
  //       bottom:0;
  //       left:0;
  //       right:0;
  //       background:var(--velys-blue);
  //       height:4px;
  //       border-radius: 10px;
  //     }
  //     cursor: pointer;
  //   }
  // }
}
