.modal-bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .times-icon {
    top: 20px;
    right: 20px;
    position: absolute;
  }
}

.modal {
  // min-height: 300px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  z-index: 9995;
  box-shadow: 0 4px 16px RGBa(0, 0, 0, 0.4);

  .blue-header {
    display: flex;
    justify-content: center;
  }

  h4 {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    color: var(--blueDocspera);
    margin-bottom: 14px;
    font-weight: 600;
  }

  .close {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 24px;
    background-color: transparent;
    border: 0;

    > div {
      margin-left: 1px;
    }
  }
}
