#schedule .agenda-card {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(0, 0, 0, 0.2);
  margin: 1.25rem 0;
  padding: 0.5rem 1rem;
  transition: All 0.15s ease-in-out;
  color: gray !important;

  //attendee status
  &.Unconfirmed {
    background-color: rgba(255, 204, 0, 0.1);
  }

  &.Confirmed {
    background-color: rgba(0, 161, 255, 0.1);
  }

  &.Vacation {
    background-color: rgba(149, 149, 168, 0.1);
  }

  &.Conference {
    background-color: rgba(149, 121, 198, 0.1);
  }

  &:hover {
    box-shadow: 0px 1px 8px 3px rgba(155, 155, 155, 1);
    cursor: pointer;
    transform: scale(1.015);
  }

  .case-color-tab {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 30px;
  }

  .case-icon-container {
    > .case-icon {
      border-radius: 100%;
      height: 20px;
      // left: 1rem;
      width: 20px;
      text-align: center;
      padding: 3px;
      color: #ffffff !important;

      &.surgery {
        background: transparent
          url('../../assets/img/event-icons/surgical-icon.svg') 0% 0% no-repeat
          padding-box;
        border-color: RGBa(255, 255, 255, 1);
      }

      &.clinic {
        background: transparent
          url('../../assets/img/event-icons/clinical-icon.svg') 0% 0% no-repeat
          padding-box;
        border-color: RGBa(255, 255, 255, 1);
      }

      &.conference {
        // background: transparent url('../../assets/img/event-icons/conference-icon.svg') 0% 0% no-repeat padding-box;
        background-color: #9579c6;
        border-color: #9579c6;
        transform: scale(0.9);
      }

      &.vacation {
        // background: transparent url('../../assets/img/event-icons/vacation-icon.svg') 0% 0% no-repeat padding-box;
        border-color: #9595a8;
        background-color: #9595a8;
        transform: rotate(-45deg) scale(0.9);
      }
    }
  }

  .case-body {
    color: var(--icon-active-gray);
    flex-grow: 1;

    > div:first-child {
      p {
        > span:first-child {
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-weight: 600;
          color: White;
          margin: 0;
          border-radius: 15px !important;
          display: Inline-Block;
          line-break: 18px;
          padding: 3px 0;
          text-align: Center;
          vertical-align: Text-Top;
          white-space: Nowrap;
          width: 165px;

          &.Surgery {
            background-color: #00a1ff;
          }

          &.Clinic {
            background-color: rgba(255, 147, 41, 1) !important;
          }

          &.Unconfirmed {
            background-color: #ffcc00;
          }

          &.Vacation {
            background-color: #9595a8;
          }

          &.Conference {
            background-color: #9579c6;
          }

          .rebook-dates {
            color: #800080;
            margin-right: 8px;
            height: 8px;
            width: 8px;
            border: 2px solid white;
            border-radius: 3px;
          }
        }

        > span:nth-child(2) {
          line-height: 20px;
          vertical-align: text-top;
          font-weight: bold;
          font-size: 14px;
          color: Gray;
        }
      }

      .readiness-badge {
        border-radius: 20px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 14px;
        margin: 0;
        padding: 3px 0;
        display: Inline-Block;
        text-align: center;
        vertical-align: text-top;
        white-space: nowrap;
        width: 154px;
        color: white;

        &.ready {
          background-color: var(--accent-green);
          color: var(--white) !important;
        }

        &.pending {
          background-color: var(--accent-yellow);
          color: var(--white) !important;
        }

        &.not-started {
          background-color: rgba(239, 45, 55, 0.7);
          color: var(--white) !important;
        }

        // > .readiness-icon{
        //   border-radius: 100%;
        //   height: 10px;
        //   margin-right: 0.5rem;
        //   margin-top: -2px;
        //   width: 10px;

        // }

        > p {
          font-size: 0.9rem;
          margin: 0;
          text-align: center;
        }
      }
    }

    > div:nth-child(2) {
      margin-top: 15px;

      .case-name {
        width: calc(100% - 180px);
      }

      .case-owner {
        padding: 0.1rem 0.5rem;
        transition: var(--hover-transition);

        &.open {
          background-color: var(--light-gray-bg);
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }

        &:hover {
          background-color: var(--light-gray-bg);
          cursor: default;

          &:not(.open) {
            border-radius: 7px;
          }
        }
      }
    }

    > div:nth-child(4),
    > div:last-child,
    .agenda-footer {
      margin-top: 15px;

      p {
        .case-location-icon {
          color: var(--medium-gray);
        }

        &:hover {
          color: var(--black);
        }
      }

      .function-icons button {
        margin-top: -5px;
        color: #ddd;
        padding: 0px;
        background-color: transparent;

        .trash-icon {
          &.open {
            color: var(--black);
          }

          &.close {
            color: #ddd;
          }

          &:hover {
            color: var(--black);
          }
        }

        &:hover {
          color: var(--black);
        }

        &.join-telehealth-btn {
          background: url('../../assets/img/icons/join-conference.svg')
            no-repeat;
          margin-right: 10px;
          height: 24px;
          line-height: 20px;
          width: 136px;
          font-size: 10px;
          margin-top: 2px;
        }

        &.invite-telehealth-btn {
          background-color: #ffcc00;
          border-radius: 15px !important;
          color: Gray;
          font-size: 12px;
          height: 24px;
          margin-right: 10px !important;
          padding: 2px 10px;
          transition: all 0.15s ease-in-out;
          border-color: #ffcc00;
          font-weight: bold;
          margin-top: 3px;

          &:hover {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2),
              0 2px 4px rgba(0, 0, 0, 0.2);
            cursor: pointer;
          }
        }

        &.invite-telehealth-disabled {
          opacity: 0.5;

          &:hover {
            cursor: help !important;
          }
        }
      }

      .function-icons button:not(:last-child) {
        margin-right: 10px;
      }

      &:hover {
        color: var(--black) !important;
      }
    }

    .all-attendees {
      background-color: var(--light-gray-bg);
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      border-top-left-radius: 7px;
      padding: 0.5rem;

      > div {
        width: 48%;

        h2 {
          font-size: 1rem;
          font-weight: 500;
          margin: 0;
        }

        hr {
          margin: 0 0 0.25rem 0;
        }
      }
    }

    .patient-details {
      margin: 10px 0 0 0;
    }

    .print-status {
      display: none;
    }

    p {
      margin: 0;
      font-size: 1rem;

      &.Tentative {
        color: #ffaa00;
        font-weight: 600;
        font-size: 16px;
      }

      &.Confirmed {
        color: #32c832;
        font-weight: 600;
        font-size: 16px;
      }
    }

    span {
      margin: 0 0.5rem;
    }
  }

  .confirm-tab {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 4px;
    width: 12px;
    overflow: hidden;

    // &.confirmed{
    //   background-color: var(--accent-light-blue);
    // }

    &.unconfirmed {
      background-color: var(--accent-yellow);
    }

    p {
      color: var(--icon-active-gray);
      font: var(--footer-text);
      font-weight: 700;
      margin: 0 0 0 -31px;
      padding: 0;
      text-transform: uppercase;
      transform: rotate(90deg);
    }
  }
}

/***** popover styling *****/
.react-tiny-popover-container {
  z-index: 9999 !important;

  .popover-arrow-container {
    z-index: 1500 !important;

    #popover-content {
      background-color: white !important;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15), 0 3px 10px rgba(0, 0, 0, 0.22) !important;
      width: 300px;
      height: 150px !important;
      text-align: center;

      > button {
        float: right;
        text-align: right;
        padding: 0px;
        margin: 0 10px 0 0;
        font-weight: 700;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.2;

        &:hover {
          opacity: 0.5;
        }
      }

      > p {
        clear: both;
      }

      .popover-btn-container {
        margin-top: 25px;

        > button {
          border-radius: 15px !important;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);
          color: #fff;
          cursor: pointer;
          display: inline-block;
          float: none !important;
          font-size: 14px;
          font-weight: inherit !important;
          height: 30px;
          opacity: 1 !important;
          width: 110px;
        }

        #no {
          background-color: #d84a38;
          margin-right: 15px;
        }

        #yes {
          background-color: #35aa47;
        }
      }
    }
  }
}

/*** print agenda view styling **/
#PrintHeaderContainer,
#PrintFooter,
#Print_CaseNotes,
#Print_SchedulingNotes,
#Print_Devices {
  display: None;
}
