:root {
  --header-1: normal 700 32px/36px 'Open Sans', sans-serif;
  --header-2: normal 500 24px/72px 'Open Sans', sans-serif;
  --body-text: normal 600 14px/16px 'Open Sans', sans-serif;
  --body-text-small: normal 400 16px/20px 'Open Sans', sans-serif;
  --dates-timestamp: normal 400 14px/18px 'Open Sans', sans-serif;
  --legend-text: normal 400 12px/16px 'Open Sans', sans-serif;
  --footer-text: normal 400 10px/12px 'Open Sans', sans-serif;
  --table-header: normal 500 16px 'Open Sans', sans-serif;
  --selector-text: normal 600 14px/18px 'Open Sans', sans-serif;

  --velys-blue: #52c8e7;
  --blueDocspera: #0096fa;
  --clinicalDocspera: #ff9329;
  --velys-blue-og: #00b4e1;
  --light-blue: #dcf4fa;
  --lighter-blue: #e8f1f4;
  --white: #ffffff;
  --black: #212121;
  --dark-blue: #1478b4;
  --light-gray-bg: #f7f8fb;
  --light-gray-bg-og: #f4f4f4;
  --light-gray-line: #f7f7f7;
  --light-gray: #c9c9c9;
  --icon-idle-gray: #9b9b9b;
  --medium-gray: #707070;
  --icon-active-gray: #4a4a4a;
  --velysReadinessGreen: #589b0b;
  --velysReadinessYellow: #e2a602;
  --velysReadinessRed: #d0011b;
  --dark-red: #ff000099;
  --pd-horizontal: #0000001a;
  --pd-light-gray: #00000099;
  --pd-border-right: #0000000a;

  --accent-light-blue: #52c8e7;
  --accent-yellow: #ffc200;
  --accent-orange: #e65722;
  --accent-green: #32c832;
  --accent-purple: #675dc5;
  --accent-red: #df0050;

  --alertDocsperaBlue: #0098e2;

  --risk-high-red: #d0011b;
  --risk-med-yellow: #e2a602;
  --risk-low-green: #589b0b;

  --hover-transition: all 150ms ease-in-out;

  --box-shadow-card: 0px 3px 6px #00000029;

  // --calendar-surgery: #e2f8ff;
  // --calendar-clinical: #fff1d6;
  --calendar-surgery: #52c8e7;
  --calendar-clinical: #ffb41d;
  --calendar-conference: #675dc5;
  --calendar-vacation: #9595a8;
  --calendar-unconfirmed: #ffcc00;
  --calendar-confirmed: #00a1ff;

  --main-h1: normal 700 24px/28px 'Open Sans', sans-serif;
  --main-h2: normal 500 16px/18px 'Open Sans', sans-serif;
  --main-h3: normal 400 16px/18px 'Open Sans', sans-serif;
  --button-text: normal 500 18px/18px 'Open Sans', sans-serif;

  --button-hover-blue: #009fc7;

  --patient-icon-male: #0098e2;
  --patient-icon-female: hotpink;
}

body {
  background-color: var(--light-gray-bg);
  color: var(--black);

  font: var(--body-text);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

// body{
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.patient-male {
  color: var(--patient-icon-male);
}

.patient-female {
  color: var(--patient-icon-female);
}

.hover-pointer:hover {
  cursor: pointer;
}

.content-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: calc(100% - 275px);
}

.content-container-2 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  max-width: calc(100% - 275px);
}

/*/ FLEXBOX CLASSES */
.flex {
  display: flex;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-center {
  align-items: center;
}

.flex.align-stretch {
  align-items: stretch;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.space-around {
  justify-content: space-around;
}

.flex.space-evenly {
  justify-content: space-evenly;
}

.flex.justify-start {
  justify-content: flex-start;
}

.flex.justify-center {
  justify-content: center;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.direction-row {
  flex-direction: row;
}

.flex.direction-column {
  flex-direction: column;
}

.flex.nowrap {
  flex-wrap: nowrap;
}

.flex.wrap {
  flex-wrap: wrap;
}

/*/ FLEXBOX CLASSES */

/* BLUR */
.blur1px {
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.blur2px {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

/*/ BLUR */

/* BUTTONS */
.btn-container {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;

  button:not(:last-child) {
    margin-right: 1rem;
  }
}

button {
  background-color: var(--white);
  border: 0;
  border-radius: 10px;
  font: normal 500 16px/16px 'Open Sans', sans-serif;
  height: 36px;
  outline: 0;
  padding: 0.5rem 1rem;
  transition: var(--hover-transition);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.circle {
    width: 36px;
    height: 36px;
  }

  &.small {
    height: 24px;
    width: 24px;
  }

  &:hover {
    cursor: pointer;
  }

  &.circle {
    border-radius: 100%;
    padding: 0;
  }

  &.btn-primary {
    background-color: var(--white);
    border: 1px var(--blueDocspera) solid;
    color: var(--blueDocspera);
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;

    &:hover {
      background-color: var(--medium-gray);
      border: 1.5px solid var(--medium-gray);
      color: var(--white);
    }
  }

  &.btn-success {
    background-color: var(--blueDocspera);
    // border: 1.5px solid var(--velys-blue);
    color: var(--white);
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;

    &:hover {
      background-color: var(--button-hover-blue);
      color: var(--white);
    }
  }

  &.btn-warning {
    background-color: var(--white);
    color: var(--white);
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;

    &:hover {
      background-color: var(--risk-high-red);
      border: 1.5px solid var(--risk-high-red);
      color: var(--white);
    }
  }

  &.btn-close {
    // border: 1.5px solid var(--black);
    border-radius: 100%;
    padding: 0;

    > div {
      margin-left: 1px;
    }

    &:hover {
      background-color: var(--risk-high-red);
      border: 1.5px solid var(--risk-high-red);
      color: var(--white);
    }
  }

  &.btn-add {
    background-color: var(--blueDocspera);
    color: var(--black);
    border-radius: 100%;
    padding: 0;

    &:hover {
      background-color: var(--button-hover-blue);
      color: var(--white);
    }
  }

  img {
    height: 20px;
    vertical-align: middle;
    width: auto;
  }
}

/*/ BUTTONS */

/* DIV WIDTH */
form div.form-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 2rem;
  // padding: 0 4rem;

  &.view {
    border-bottom: 1px solid var(--light-gray-bg-og);
    margin: 0.5rem 2rem;

    label {
      font: var(--legend-text);
      color: var(--medium-gray);
      text-transform: uppercase;
      font-weight: 400;

      > b {
        margin-left: 0;
        text-transform: initial;
      }
    }
  }

  &.side-content {
    justify-content: flex-end;
  }
}

div.width100 {
  flex: 0 0 100%;
}

div.width90 {
  flex: 0 0 88%;
}

div.width80 {
  flex: 0 0 78%;
}

div.width70 {
  flex: 0 0 68%;
}

div.width60 {
  flex: 0 0 58%;
}

div.width50 {
  flex: 0 0 48%;
}

div.width40 {
  flex: 0 0 38%;
}

div.width33 {
  flex: 0 0 32%;
}

div.width30 {
  flex: 0 0 28%;
}

div.width20 {
  flex: 0 0 18%;
}

div.width10 {
  flex: 0 0 8%;
}

form label {
  display: block;
  font: var(--dates-timestamp);
  font-weight: 500;
  margin: 0;

  &:hover {
    cursor: pointer;
  }

  > b {
    font-weight: 400;
    margin-left: 0.25rem;
  }

  &.noflex {
    margin-top: 0.5rem;
    padding: 0rem 4rem;
  }
}

form input:not([type='checkbox']),
form select,
form textarea {
  background: var(--white);
  border: 1px solid var(--medium-gray);
  border-radius: 10px;
  font: var(--body-text-small);
  line-height: 24px;
  outline: 0;
  margin: 0.5rem 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  transition: all 100ms ease;

  &:focus {
    // border: 1px solid var(--icon-active-gray);
    border: 1px solid var(--dark-blue);
    animation: FocusField 2s infinite;
  }
}

form input:not([type='checkbox']) {
  height: 32px;
  width: 99%;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

form select {
  height: 36px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

form textarea {
  min-height: 6rem;
  padding: 0.5rem 0.25rem;
  resize: none;
  width: 98%;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: var(--light-gray-bg-og);
}

.input-field-icon {
  bottom: 17px;
  display: inline;
  // float: right;
  position: absolute;
  right: 4px;
}

/*/ DIV WIDTH */

/* CSS ANIMATIONS */
@keyframes FocusField {
  0% {
    // box-shadow: 0 0 10px rgba(74,74,74,.1);
    box-shadow: 0 0 10px rgba(20, 120, 180, 0.1);
  }
  50% {
    // box-shadow: 0 0 10px rgba(74,74,74,.8);
    box-shadow: 0 0 10px rgba(20, 120, 180, 0.8);
  }
  100% {
    // box-shadow: 0 0 10px rgba(74,74,74,.1);
    box-shadow: 0 0 10px rgba(20, 120, 180, 0.1);
  }
}

/*/ CSS ANIMATIONS */

/* remove arrows from number input field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/*/ remove arrows from number input field */

// OVERFLOW
.docbox-open {
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.disabled {
  cursor: no-drop;
  opacity: 0.5;
  pointer-events: none;
  transition: opacity 400ms;
}

.flex-grow {
  flex-grow: 1;
}

.hidden {
  display: none;
}

a {
  color: var(--dark-blue);
}
